<div
  class="d-flex flex-column"
  [class.layout-sidebar--small]="!isMobile && !visibleFullList"
  [class.layout-sidebar--normal]="!isMobile && visibleFullList"
>
  <ct-link
    *ngIf="!isMobile"
    [link]="['/my-account/explore']"
    class="logo-container"
    [class.logo-container--small]="!visibleFullList"
  >
    <img
      class="logo"
      alt="logo"
      [src]="visibleFullList ? 'assets/logos/main-logo-light.png' : 'assets/logos/logo-small.png'"
      loading="lazy"
    />
  </ct-link>
  <ct-search-autocomplete *ngIf="isMobile" class="search-box mr-4 ml-4 mt-4"></ct-search-autocomplete>
  <div *ngIf="!isMobile" class="add-trip__button" (click)="trip?.id ? addToTripDialog() : openNewTripDialog()">
    <ct-button variant="raised" color="secondary">
      <ct-icon color="secondary" [class.mr-2]="visibleFullList" icon="add-circle"></ct-icon>
      <span *ngIf="visibleFullList">{{
        trip?.id
          ? ('MY_ACCOUNT.MY_TRIPS_FEATURE.ADD_TO_TRIP' | translate)
          : ('MY_ACCOUNT.MY_TRIPS_FEATURE.NEW_TRIP' | translate)
      }}</span>
    </ct-button>
  </div>
  <mat-nav-list>
    <a
      mat-list-item
      class="menu-item"
      routerLinkActive="menu-item--active"
      [routerLink]="nav.route"
      *ngFor="let nav of accountMenuItems"
    >
      <ct-icon [icon]="nav.icon" class="mr-2"></ct-icon>
      {{ visibleFullList ? (nav.titleKey | translate) : '' }}</a
    >

    <div class="menu-items__separator d-flex justify-content-center">
      <p>{{ visibleFullList ? 'COMMUNITY' : '&nbsp;' }}</p>
    </div>

    <ng-container *ngFor="let nav of generalMenuItems">
    <a
      mat-list-item
      class="menu-item"
      routerLinkActive="menu-item--active"
      [routerLink]="nav.route"
      *ngIf='!nav.hidden'
    >
      <ct-icon [icon]="nav.icon" class="mr-2"></ct-icon>
      {{ visibleFullList ? (nav.titleKey | translate) : '' }}</a
    >
    </ng-container>
  </mat-nav-list>
  <div class="toggle-list">
    <ct-icon *ngIf="!isMobile" [icon]="visibleFullList ? 'collapse' : 'expand'" (click)="onToggleList()"></ct-icon>
  </div>
</div>
