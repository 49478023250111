import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, OnDestroy, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthQuery, AuthService } from '@ct/auth';
import { BlogPost, ChannelVideoUploadApiService, GroupTimelineType, UserProfile, VideoChannelEntity } from '@ct/core';
import { entitySlugUrl, GroupApiService } from '@ct/shared';
import { Observable, of, Subject } from 'rxjs';
import { catchError, finalize, map, switchMap, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'ct-channel-sidebar',
  templateUrl: './channel-sidebar.component.html',
  styleUrls: ['./channel-sidebar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChannelSidebarComponent implements OnDestroy {
  @Output() showStories: EventEmitter<void> = new EventEmitter<void>();
  public currentUser$: Observable<UserProfile | null> = this.authService
    .getUserProfile()
    .pipe(catchError(() => of(null)));

  get isLoggedIn$(): Observable<boolean> {
    return this.currentUser$.pipe(map((user) => !!user));
  }

  public posts: BlogPost[] = [];
  protected channel: VideoChannelEntity;

  private destroyed$ = new Subject();

  constructor(
    private authService: AuthService,
    private groupApiService: GroupApiService,
    private videoUploadApiService: ChannelVideoUploadApiService,
    private changeDetectorRef: ChangeDetectorRef,
    private authQuery: AuthQuery,
    private route: ActivatedRoute
  ) {
    this.route.params
      .pipe(
        takeUntil(this.destroyed$),
        switchMap(({ channelName }) => {
          return this.getChannelInfo(channelName ?? this.authQuery.profile?.userId);
        })
      )
      .subscribe(() => this.loadPosts());
  }

  getLink(id?: string | undefined, slug?: string | undefined): string {
    const url = entitySlugUrl('', { id, slug });
    return `/stories/${url}`;
  }

  onShowStories() {
    this.showStories.emit();
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  getChannelInfo(channelName: string) {
    if (!channelName) {
      return of(null);
    }

    return this.videoUploadApiService.getChannel(channelName).pipe(
      takeUntil(this.destroyed$),
      tap((channel) => {
        this.channel = channel;
        this.changeDetectorRef.markForCheck();
      })
    );
  }

  loadPosts() {
    this.groupApiService
      .getTimeline(this.channel.id as string, {
        range: { limit: 3 },
        type: GroupTimelineType.Story
      })
      .pipe(
        finalize(() => {
          this.changeDetectorRef.markForCheck();
        })
      )
      .subscribe((timelines) => (this.posts = timelines.map(({ story }) => story as BlogPost).filter(Boolean)));
  }
}
