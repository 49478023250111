import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogButton } from '@ct/components/dialog';
import { SelectOption } from '@ct/components/select';
import { SpinnerService } from '@ct/components/spinner/services';
import { ChannelsQuery, FormStateDispatcher, GroupTimeline, GroupTimelineType } from '@ct/core';
import { Observable, of, Subject } from 'rxjs';
import { finalize, map, switchMap, takeUntil } from 'rxjs/operators';

import { EntityType } from '../../enums';
import { GroupApiService } from '../group-shared/services';
import { JournalEntryApiService } from '../trip-shared/services';

@Component({
  selector: 'ct-repost-channel-dialog',
  templateUrl: './repost-channel-dialog.component.html',
  styleUrls: ['./repost-channel-dialog.component.scss'],
  providers: [FormStateDispatcher],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RepostChannelDialogComponent implements OnDestroy {
  public readonly destroyed$ = new Subject<void>();

  public isLoading = false;

  public buttons: DialogButton[] = [
    {
      labelKey: 'COMMON.CANCEL',
      color: 'primary',
      clicked: () => this.onCancel()
    },
    {
      labelKey: 'Repost',
      disabled: true,
      clicked: () => {
        this.onSave();
      }
    }
  ];

  public channelsCtrl = new FormControl<string>('', [Validators.required]);
  public channelsList$: Observable<SelectOption[]> = this.channelsQuery.selectChannels$.pipe(
    map((channels) => channels.map((channel) => ({ label: channel.title, value: channel.id as string })))
  );

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { entityId: string; entityType: EntityType },
    private dialogRef: MatDialogRef<RepostChannelDialogComponent>,
    private spinnerService: SpinnerService,
    private groupApiService: GroupApiService,
    private journalEntryApiService: JournalEntryApiService,
    private changeDetectorRef: ChangeDetectorRef,
    private channelsQuery: ChannelsQuery
  ) {
    this.channelsCtrl.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe((channelId) => {
      this.buttons[1].disabled = this.channelsCtrl.invalid;
      this.buttons = [...this.buttons];
      this.changeDetectorRef.detectChanges();
    });
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  onSave() {
    this.isLoading = true;
    this.spinnerService.show();
    this.changeDetectorRef.markForCheck();

    const channelId = this.channelsCtrl.value as string;
    const timeline: Partial<GroupTimeline> = {
      entityId: this.data.entityId,
      type: this.getEntityType(this.data.entityType)
    };

    let action$: Observable<Partial<GroupTimeline>>;

    if (timeline.type === GroupTimelineType.Memory && !timeline.entityId) {
      action$ = this.journalEntryApiService
        .create({ body: timeline.body, groupIds: [channelId] })
        .pipe(map((memory) => ({ ...timeline, body: undefined, entityId: memory.id })));
    } else {
      action$ = of(timeline);
    }

    action$
      .pipe(
        switchMap((data) => this.groupApiService.addToTimeline(channelId, data)),
        finalize(() => {
          this.isLoading = false;
          this.spinnerService.hide();
          this.changeDetectorRef.markForCheck();
        })
      )
      .subscribe(() => {
        this.dialogRef.close(true);
      });
  }

  onCancel() {
    this.dialogRef.close();
  }

  getEntityType(type: EntityType): GroupTimelineType {
    switch (type) {
      case EntityType.Memory:
        return GroupTimelineType.Memory;
      default:
        return type as unknown as GroupTimelineType;
    }
  }
}
