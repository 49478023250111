import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogButton } from '@ct/components/dialog';
import { SpinnerService } from '@ct/components/spinner/services';
import { DestroyableFeature, Features, FormStateDispatcher } from '@ct/core';
import { QuillModules } from 'ngx-quill';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { EntityType } from '../../enums';
import { ReportApiService } from '../../services/report-api.service';

const QUILL_MODULES_CONFIG: QuillModules = {
  toolbar: [[{ header: [1, 2, 3, 4, 5, 6, false] }], ['bold', 'italic', { list: 'bullet' }, 'link', 'image']]
};

@Component({
  selector: 'ct-report-inappropriate-dialog',
  templateUrl: './report-inappropriate-dialog.component.html',
  styleUrls: ['./report-inappropriate-dialog.component.scss'],
  providers: [FormStateDispatcher],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@Features([DestroyableFeature()])
export class ReportInappropriateDialogComponent {
  public readonly destroyed$: Observable<void>;
  public readonly modules: QuillModules = QUILL_MODULES_CONFIG;

  public readonly form = new UntypedFormGroup({
    reason: new UntypedFormControl('', [Validators.required, Validators.minLength(20)])
  });

  public isLoading = false;

  public buttons: DialogButton[] = [
    {
      labelKey: 'COMMON.CANCEL',
      color: 'primary',
      clicked: () => this.onCancel()
    },
    {
      labelKey: 'COMMON.SAVE',
      clicked: () => {
        this.onSave();
      }
    }
  ];

  constructor(
    @Inject(MAT_DIALOG_DATA) public storyId: string,
    private dialogRef: MatDialogRef<ReportInappropriateDialogComponent>,
    private formState: FormStateDispatcher,
    private spinnerService: SpinnerService,
    private reportApiService: ReportApiService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  onSave() {
    this.formState.onSubmit.notify();

    if (this.form.invalid) {
      return;
    }

    this.isLoading = true;
    this.spinnerService.show();
    this.changeDetectorRef.markForCheck();

    const flaggedEntity = {
      reason: this.form.value.reason,
      entityId: this.storyId,
      entityType: EntityType.Story
    };

    this.reportApiService
      .flagAsInappropriate(flaggedEntity)
      .pipe(
        finalize(() => {
          this.isLoading = false;
          this.spinnerService.hide();
          this.changeDetectorRef.markForCheck();
        })
      )
      .subscribe(() => {
        this.dialogRef.close(true);
      });
  }

  onCancel() {
    this.dialogRef.close();
  }
}
