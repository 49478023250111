import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthQuery } from '@ct/auth';
import { DialogService } from '@ct/components';
import { ChannelsQuery, Features, FriendStatus, RelationUserProfile, TitleConfig, TitleFeature } from '@ct/core';
import {
  AddToTripDialogComponent,
  CampsiteQuery,
  JournalEntryQuery,
  Mode,
  trackById,
  Trip,
  TripCampsite,
  TripJournalEntry,
  TripJournalEntryApiService
} from '@ct/shared';
import { WritingJournalAddDialogComponent } from '@ct/shared/modules/add-to-writings-dialog';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError, filter, switchMap, take, takeUntil, tap } from 'rxjs/operators';

import { MY_ACCOUNT_POSTS_FEATURE_LIMIT } from '../../../../constants';
import { FriendsQuery } from '../../../../services/store/friends.query';
import { CAMPISITE_ALL_FILTER } from '../../constants';

interface WritingQueryParams {
  offset: number;
  campsiteId?: string;
}

const DEFAULT_OFFSET = 0;

@Component({
  selector: 'ct-my-account-trip-journals',
  templateUrl: './my-account-trip-journals.component.html',
  styleUrls: ['./my-account-trip-journals.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@Features([TitleFeature()])
export class MyAccountTripJournalsComponent implements OnInit, OnDestroy {
  public readonly limit = MY_ACCOUNT_POSTS_FEATURE_LIMIT;
  public titleConfig: TitleConfig = {
    titleKey: 'MAIN.FEATURES.MY_ACCOUNT_POSTS'
  };
  public loading = false;
  public journalEntries: TripJournalEntry[] = [];
  public trip: Trip;
  public showLoadButton = true;
  campsites: TripCampsite[] = [];

  public queryParams: WritingQueryParams = {
    offset: DEFAULT_OFFSET
  };

  public readonly campsiteAllFilter: TripCampsite = CAMPISITE_ALL_FILTER as TripCampsite;

  get isAuthor(): boolean {
    return this.authQuery.getValue().profile?.userId === this.trip?.authorId;
  }
  public hasChannels$: Observable<boolean> = this.channelsQuery.hasChannels$;

  public trackByFn = trackById;

  private destroyed$ = new Subject();
  private friends$: Observable<RelationUserProfile[]> = this.friendsQuery.selectAll({
    filterBy: ({ friendStatus }) => friendStatus === FriendStatus.Accepted
  });
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private changeDetectorRef: ChangeDetectorRef,
    private authQuery: AuthQuery,
    private campsiteQuery: CampsiteQuery,
    private journalEntryQuery: JournalEntryQuery,
    private friendsQuery: FriendsQuery,
    private channelsQuery: ChannelsQuery,
    private tripJournalEntryApiService: TripJournalEntryApiService,
    private dialogService: DialogService
  ) {}

  onFilterChanged(campsite: TripCampsite) {
    if (campsite.id === this.queryParams.campsiteId) {
      return;
    }

    this.queryParams = { offset: 0, campsiteId: (campsite?.id as string) || undefined };

    this.loadData(this.queryParams);
  }

  ngOnInit() {
    this.trip = this.route.snapshot.data?.trip;

    this.campsiteQuery
      .selectByTripId(this.trip.id as string)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((campsites) => {
        this.campsites = campsites;
        this.changeDetectorRef.markForCheck();
      });

    this.journalEntryQuery
      .selectBy(this.trip.id as string)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((journalEntries) => {
        this.journalEntries = this.queryParams?.campsiteId
          ? journalEntries.filter(({ campsiteId }) => campsiteId === this.queryParams.campsiteId)
          : journalEntries;
        this.showLoadButton = !(this.journalEntries.length === 0 || this.journalEntries.length < this.limit);
        this.changeDetectorRef.markForCheck();
      });

    this.route.queryParams.pipe(take(1)).subscribe(({ offset, campsiteId }) => {
      this.queryParams = {
        ...this.queryParams,
        campsiteId: campsiteId || this.queryParams.campsiteId,
        offset: +offset || this.queryParams.offset
      };
      this.refreshUrlQueryParams(this.queryParams);
    });
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  loadData(params: WritingQueryParams, shouldRefreshUrl = true) {
    return this.loadJournals(params)
      .pipe(filter(() => shouldRefreshUrl))
      .subscribe(() => this.refreshUrlQueryParams(this.queryParams));
  }

  onPrev() {
    const nextOffset = this.queryParams.offset - this.limit;
    if (nextOffset < 0) {
      return;
    }
    this.queryParams.offset = nextOffset;
    this.loadData(this.queryParams);
  }

  onNext() {
    if (this.journalEntries?.length < this.limit) {
      return;
    }
    this.queryParams.offset = this.queryParams.offset + this.limit;
    this.loadData(this.queryParams);
  }

  onScroll() {
    if (this.loading || !this.showLoadButton) {
      return false;
    }
    this.loading = true;
    this.queryParams.offset = this.queryParams.offset + this.limit;
    this.loadData(this.queryParams, false);
  }

  loadJournals({ offset, campsiteId }: WritingQueryParams) {
    this.loading = true;
    return this.tripJournalEntryApiService
      .getAllByTripId(this.trip.id as string, { range: { limit: this.limit, offset }, campsiteId })
      .pipe(
        tap(() => (this.loading = false)),
        catchError((err) => {
          this.loading = false;
          return throwError(err);
        })
      );
  }

  refreshUrlQueryParams({ offset, campsiteId }: WritingQueryParams) {
    this.router
      .navigate([], {
        queryParams: { offset, campsiteId },
        queryParamsHandling: 'merge',
        relativeTo: this.route
      })
      .then(() => {
        this.journalEntryQuery
          .selectBy(this.trip.id as string)
          .pipe(take(1))
          .subscribe((journalEntries) => {
            this.journalEntries = this.queryParams?.campsiteId
              ? journalEntries.filter(({ campsiteId }) => campsiteId === this.queryParams.campsiteId)
              : journalEntries;
            this.showLoadButton = !(this.journalEntries.length === 0 || this.journalEntries.length < this.limit);
            this.changeDetectorRef.markForCheck();
          });
        this.changeDetectorRef.detectChanges();
      });
  }

  editJournal(item: TripJournalEntry) {
    this.dialogService
      .open(WritingJournalAddDialogComponent, {
        data: {
          journalEntry: item,
          mode: Mode.Edit,
          friends$: this.friends$
        }
      })
      .afterClosed()
      .pipe(take(1), filter(Boolean))
      .subscribe(() => {
        this.queryParams = {
          ...this.queryParams,
          offset: DEFAULT_OFFSET
        };
        this.loadData(this.queryParams);
      });
  }

  addDialog() {
    this.dialogService
      .open(AddToTripDialogComponent, { data: this.trip })
      .afterClosed()
      .pipe(
        take(1),
        filter((options) => options && Boolean(options.reload)),
        switchMap(() => this.loadJournals({ offset: 0 }))
      )
      .subscribe();
  }
}
