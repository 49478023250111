import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IconModule } from '@ct/components/icon';

import { RepostEntityComponent } from './repost-entity.component';

@NgModule({
  declarations: [RepostEntityComponent],
  imports: [CommonModule, IconModule],
  exports: [RepostEntityComponent]
})
export class RepostEntityModule {}
