import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatSliderModule } from '@angular/material/slider';
import {
  AdBannersModule,
  ButtonModule,
  ChannelRowModule,
  DialogModule,
  EmptyCallToActionModule,
  EntityCommentsModule,
  IconModule,
  ImageBannerModule,
  ImageModule,
  LikeModule,
  LinkModule,
  SidebarModule,
  SpinnerModule,
  TabHeaderModule,
  TabsMobileModule,
  TextareaModule,
  UserAvatarModule
} from '@ct/components';
import { FileUploadSharedModule } from '@ct/components/file-upload/modules/file-upload-shared';
import { PipesModule } from '@ct/core';
import { LayoutModule } from '@ct/layout';
import { BlogSharedModule, MyVideoPlayerModule, TripSharedModule, VideoItemModule } from '@ct/shared';
import { TranslateModule } from '@ngx-translate/core';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

import { ChannelComponent } from './channel';
import { ChannelAddEntryComponent } from './channel-add-entry';
import { ChannelListComponent } from './channel-list';
import { ChannelRoutingModule } from './channel-routing.module';
import { ChannelSidebarComponent } from './channel-sidebar';
import { ChannelTimelineComponent } from './channel-timeline';
import { SelectDocumentDialogComponent } from './select-document-dialog';
import { SelectGroupEntityDialogComponent } from './select-group-entity-dialog';
import { SelectJournalDialogComponent } from './select-journal-dialog';
import { SelectStoryDialogComponent } from './select-story-dialog';
import { SelectTripDialogComponent } from './select-trip-dialog';
import { SelectVideoDialogComponent } from './select-video-dialog';
import { UserChannelComponent } from './user-channel';
import { UserChannelsListComponent } from './user-channels-list';

@NgModule({
  declarations: [
    UserChannelComponent,
    ChannelComponent,
    ChannelListComponent,
    UserChannelsListComponent,
    ChannelTimelineComponent,
    ChannelAddEntryComponent,
    SelectGroupEntityDialogComponent,
    SelectJournalDialogComponent,
    SelectStoryDialogComponent,
    SelectTripDialogComponent,
    ChannelSidebarComponent,
    SelectVideoDialogComponent,
    SelectDocumentDialogComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ChannelRoutingModule,
    LayoutModule,
    TranslateModule,
    ImageBannerModule,
    FileUploadSharedModule,
    MatSliderModule,
    UserAvatarModule,
    ButtonModule,
    BlogSharedModule,
    InfiniteScrollModule,
    TabHeaderModule,
    TabsMobileModule,
    TripSharedModule,
    EntityCommentsModule,
    IconModule,
    LikeModule,
    ChannelRowModule,
    EmptyCallToActionModule,
    MyVideoPlayerModule,
    LinkModule,
    VideoItemModule,
    ImageModule,
    DialogModule,
    PipesModule,
    TextareaModule,
    SidebarModule,
    AdBannersModule,
    SpinnerModule
  ]
})
export class ChannelModule {}
