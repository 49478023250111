import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormGroup, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogService, SelectOption, SpinnerService } from '@ct/components';
import { DialogButton } from '@ct/components/dialog';
import {
  ChannelLink,
  ChannelLinkType,
  ChannelVideoUploadApiService,
  DestroyableFeature,
  Features,
  FormStateDispatcher,
  LocalStorageService,
  VideoChannelEntity
} from '@ct/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { SOCIAL_LINKS_OPTIONS } from './social-links.options';

@Component({
  selector: 'ct-channel-edit-links-dialog',
  templateUrl: './channel-edit-links-dialog.component.html',
  styleUrls: ['./channel-edit-links-dialog.component.scss'],
  providers: [FormStateDispatcher],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@Features([DestroyableFeature()])
export class ChannelEditLinksDialogComponent implements OnInit {
  public readonly destroyed$: Observable<void>;

  public isLoading = false;
  protected readonly socialLinkOptionsList: SelectOption[] = SOCIAL_LINKS_OPTIONS;

  public form = new UntypedFormGroup({
    links: new FormArray([]),
    socialLinks: new FormArray([])
  });

  public get buttons(): DialogButton[] {
    const cancelButton: DialogButton = {
      labelKey: 'COMMON.CANCEL',
      color: 'primary',
      clicked: () => this.dialogRef.close()
    };

    const saveButton: DialogButton = {
      labelKey: 'COMMON.SAVE',
      clicked: () => {
        this.update();
      },
      color: 'accent'
    };
    return [cancelButton, saveButton];
  }

  get channel(): VideoChannelEntity {
    return this.data.channel;
  }

  get authorId(): string {
    return this.data.authorId ?? this.channel?.adminIds?.[0];
  }

  public get linksControl(): FormArray {
    return this.form.get('links') as FormArray;
  }

  public get socialLinksControl(): FormArray {
    return this.form.get('socialLinks') as FormArray;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public readonly data: any,
    private dialogRef: MatDialogRef<ChannelEditLinksDialogComponent>,
    protected localStorageService: LocalStorageService,
    protected dialogService: DialogService,
    protected translateService: TranslateService,
    private formState: FormStateDispatcher,
    private changeDetectorRef: ChangeDetectorRef,
    private spinnerService: SpinnerService,
    private channelVideoUploadApiService: ChannelVideoUploadApiService
  ) {}

  ngOnInit() {
    // filter out duplicated URLs
    const linksMap = new Map((this.channel.links ?? []).map((link) => [link.url, link]));
    const socialLinksMap = new Map((this.channel.socialLinks ?? []).map((link) => [link.url, link]));
    const links = [...linksMap.values()];
    const socialLinks = [...socialLinksMap.values()];

    links.forEach(() => this.onAddLink());
    socialLinks.forEach(() => this.onAddSocialLink());
    this.form.setValue({
      links,
      socialLinks
    });

    if (links.length === 0) {
      this.onAddLink();
    }

    if (socialLinks.length === 0) {
      this.onAddSocialLink();
    }
  }

  onAddLink(): void {
    const group = Object.keys(new ChannelLink('', '', ChannelLinkType.Url)).reduce(
      (acc: { [key: string]: AbstractControl }, name) => {
        acc[name] = new FormControl(null, Validators.required);
        return acc;
      },
      {}
    );
    group.type.setValue(ChannelLinkType.Url);
    this.linksControl.push(new FormGroup(group));
  }

  onAddSocialLink(): void {
    const group = Object.keys(new ChannelLink('', '', ChannelLinkType.Url)).reduce(
      (acc: { [key: string]: AbstractControl }, name) => {
        acc[name] = new FormControl(null, Validators.required);
        return acc;
      },
      {}
    );
    group.type.setValue(ChannelLinkType.Url);
    this.socialLinksControl.push(new FormGroup(group));
  }

  onDeleteLink(index: number): void {
    this.linksControl.removeAt(index);
  }

  onDeleteSocialLink(index: number): void {
    this.socialLinksControl.removeAt(index);
  }

  update() {
    this.formState.onSubmit.notify();

    if (this.form.invalid) {
      return;
    }

    this.isLoading = true;
    this.spinnerService.show();
    this.changeDetectorRef.markForCheck();

    const { links, socialLinks } = this.form.value as Pick<VideoChannelEntity, 'links' | 'socialLinks'>;
    const id = this.channel.id as string;
    return this.channelVideoUploadApiService
      .update(id, { links, socialLinks })
      .pipe(
        finalize(() => {
          this.isLoading = false;
          this.spinnerService.hide();
          this.changeDetectorRef.markForCheck();
        })
      )
      .subscribe((response) => {
        this.dialogRef.close(response);
      });
  }
}
