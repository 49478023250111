import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { DialogService, SpinnerService } from '@ct/components';
import {
  ChannelDocumentUploadApiService,
  DestroyableFeature,
  DOCUMENT_MIME_TYPES,
  DocumentUpload,
  Features,
  FormStateDispatcher
} from '@ct/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { finalize, map, switchMap } from 'rxjs/operators';

@Component({
  selector: 'ct-select-document-dialog',
  templateUrl: './select-document-dialog.component.html',
  styleUrls: ['./select-document-dialog.component.scss'],
  providers: [FormStateDispatcher],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@Features([DestroyableFeature()])
export class SelectDocumentDialogComponent {
  static dialogConfig: MatDialogConfig = {
    minWidth: '700px'
  };
  public readonly destroyed$: Observable<void>;
  protected readonly uploadingMedia$ = new BehaviorSubject<boolean>(false);
  protected readonly documentMimeTypes = DOCUMENT_MIME_TYPES.join(',');

  constructor(
    protected dialogService: DialogService,
    private dialogRef: MatDialogRef<SelectDocumentDialogComponent>,
    private spinnerService: SpinnerService,
    private documentUploadApiService: ChannelDocumentUploadApiService,
    @Inject(MAT_DIALOG_DATA) private readonly data: { channelId: string }
  ) {}

  onSelect(document: DocumentUpload) {
    this.dialogRef.close(document);
  }

  async onSelectDocuments(files: File[]) {
    this.spinnerService.show({ instant: true });

    const file = files[0];

    const { name: filename, type: mimetype, size } = file;

    this.documentUploadApiService
      .getUploadLink({
        mimetype,
        size,
        filename,
        originalname: filename,
        relatedChannelIds: this.data.channelId ? [this.data.channelId] : []
      })
      .pipe(
        switchMap(({ uploadLink, ...document }) => {
          return this.documentUploadApiService
            .useUploadLink(files[0], uploadLink)
            .pipe(map(() => document as DocumentUpload));
        }),
        finalize(() => {
          this.spinnerService.hide();
        })
      )
      .subscribe((document) => this.onSelect(document));
  }
}
