import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { AuthService } from '@ct/auth';
import { ChannelVideoUploadApiService, VideoChannelEntity } from '@ct/core';
import { switchMap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ChannelsAdminResolver implements Resolve<VideoChannelEntity[]> {
  constructor(private channelVideoUploadApiService: ChannelVideoUploadApiService, private authService: AuthService) {}

  resolve(activatedRouteSnapshot: ActivatedRouteSnapshot) {
    const offset = activatedRouteSnapshot.queryParams?.offset as number;
    return this.authService
      .getUserProfile()
      .pipe(
        switchMap((user) =>
          this.channelVideoUploadApiService.getAll({ range: { limit: 20, offset }, adminId: user.userId })
        )
      );
  }
}
