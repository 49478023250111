import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  BaseHttpService,
  Group,
  GroupFilterType,
  GroupInvitation,
  GroupTimeline,
  GroupTimelineType,
  GroupType,
  HeaderType,
  RequestRange,
  UserProfile
} from '@ct/core';
import { environment } from '@ct/environment';
import { Observable } from 'rxjs';

import { SortOrder } from '../../../enums';

export interface GroupMembersFilterParams {
  groupId: string;
  sortOrder?: SortOrder | undefined;
  match?: string;
}

const endpoint = environment.groupApiBaseUrl;

@Injectable({ providedIn: 'root' })
export class GroupApiService extends BaseHttpService {
  constructor(public httpClient: HttpClient) {
    super(httpClient, endpoint);
  }

  getAll({
    range,
    sortOrder = SortOrder.Desc,
    sortBy,
    visibility
  }: {
    range?: RequestRange;
    sortOrder?: SortOrder;
    sortBy?: keyof Group;
    visibility?: GroupType;
  }): Observable<Group[]> {
    const headers = {
      [HeaderType.Accept]: 'application/json'
    };
    let params = new HttpParams();
    if (range?.limit !== undefined && range.limit !== null) {
      params = params.append('limit', range.limit as number);
    }
    if (range?.offset !== undefined && range.offset !== null) {
      params = params.append('offset', range.offset as number);
    }
    if (visibility !== undefined && visibility !== null) {
      params = params.append('visibility', visibility);
    }
    if (sortOrder !== undefined && sortOrder !== null) {
      params = params.append('sortOrder', sortOrder);
    }
    if (sortBy !== undefined && sortBy !== null) {
      params = params.append('sortBy', sortBy);
    }
    return this.get(``, params, { headers, withCredentials: true });
  }

  findAllMyGroups({
    range,
    sortOrder = SortOrder.Desc,
    sortBy,
    visibility
  }: {
    range?: RequestRange;
    sortOrder?: SortOrder;
    sortBy?: keyof Group;
    visibility?: GroupType;
  }): Observable<Group[]> {
    const headers = {
      [HeaderType.Accept]: 'application/json'
    };
    let params = new HttpParams();
    if (range?.limit !== undefined && range.limit !== null) {
      params = params.append('limit', range.limit as number);
    }
    if (range?.offset !== undefined && range.offset !== null) {
      params = params.append('offset', range.offset as number);
    }
    if (visibility !== undefined && visibility !== null) {
      params = params.append('visibility', visibility);
    }
    if (sortOrder !== undefined && sortOrder !== null) {
      params = params.append('sortOrder', sortOrder);
    }
    if (sortBy !== undefined && sortBy !== null) {
      params = params.append('sortBy', sortBy);
    }
    return this.get(`my`, params, { headers, withCredentials: true });
  }

  findAllMemberGroups({
    range,
    filterType
  }: {
    range?: RequestRange;
    filterType: GroupFilterType;
  }): Observable<Group[]> {
    const headers = {
      [HeaderType.Accept]: 'application/json'
    };
    let params = new HttpParams();
    params = params.append('filterType', filterType);

    if (range?.limit !== undefined && range.limit !== null) {
      params = params.append('limit', range.limit as number);
    }
    if (range?.offset !== undefined && range.offset !== null) {
      params = params.append('offset', range.offset as number);
    }
    return this.get(`membership`, params, { headers, withCredentials: true });
  }

  findAllGroupMembers(
    groupId: string,
    {
      range,
      filterType
    }: {
      range?: RequestRange;
      filterType?: GroupFilterType;
    }
  ): Observable<UserProfile[]> {
    const headers = {
      [HeaderType.Accept]: 'application/json'
    };
    let params = new HttpParams();
    if (filterType !== undefined && filterType !== null) {
      params = params.append('filterType', filterType);
    }

    if (range?.limit !== undefined && range.limit !== null) {
      params = params.append('limit', range.limit as number);
    }
    if (range?.offset !== undefined && range.offset !== null) {
      params = params.append('offset', range.offset as number);
    }
    return this.get(`${groupId}/members`, params, { headers, withCredentials: true });
  }

  getById(id: string): Observable<Group> {
    return this.get(`${id}`);
  }

  remove(id: string): Observable<undefined> {
    return this.delete(`${id}`);
  }

  create(group: Group) {
    const request = this.toRequest(group);
    return this.post('', request);
  }

  update(id: string, group: Partial<Group>) {
    const request = this.toRequest(group);
    return this.patch(`${id}`, request);
  }

  getTimeline(
    groupId: string,
    {
      range,
      type
    }: {
      range?: RequestRange;
      type?: GroupTimelineType;
    }
  ): Observable<GroupTimeline[]> {
    const headers = {
      [HeaderType.Accept]: 'application/json'
    };
    let params = new HttpParams();
    if (range?.limit !== undefined && range.limit !== null) {
      params = params.append('limit', range.limit as number);
    }
    if (range?.offset !== undefined && range.offset !== null) {
      params = params.append('offset', range.offset as number);
    }
    if (type !== undefined && type !== null) {
      params = params.append('type', type);
    }
    return this.get<GroupTimeline[]>(`${groupId}/timeline`, params, { headers, withCredentials: true });
  }

  addToTimeline(groupId: string, body: Partial<GroupTimeline>) {
    return this.post(`${groupId}/timeline`, body);
  }

  removeTimeline(groupId: string, id: string) {
    return this.delete(`${groupId}/timeline/${id}`);
  }

  removeEntityFromTimeline(groupId: string, entityId: string) {
    return this.delete(`${groupId}/timeline/entity/${entityId}`);
  }

  sendGroupInvitation(groupId: string, userId: string) {
    return this.post(`${groupId}/members`, new GroupInvitation(userId));
  }

  removeUserFromGroup(groupId: string, userId: string) {
    return this.delete(`${groupId}/members/${userId}`);
  }

  addUserToGroup(groupId: string, userId: string) {
    return this.patch(`${groupId}/members/${userId}`, {});
  }

  joinGroup(groupId: string) {
    return this.post(`membership/${groupId}`, {});
  }

  acceptInvitation(groupId: string) {
    return this.patch(`membership/${groupId}`, {});
  }

  cancelRequest(groupId: string) {
    return this.delete(`membership/${groupId}`);
  }

  getPossibleMembers({
    match,
    groupId,
    sortOrder = SortOrder.Desc
  }: GroupMembersFilterParams): Observable<UserProfile[]> {
    let params = new HttpParams();
    if (sortOrder !== undefined && sortOrder !== null) {
      params = params.append('sortOrder', sortOrder);
    }
    if (match !== undefined && match !== null) {
      params = params.append('match', match);
    }
    return this.get(`${groupId}/possible-members`, params);
  }

  private toRequest(group: Partial<Group>): Partial<Group> {
    const { id, image, ...value } = group;

    if (image !== undefined) {
      return {
        ...value,
        imageId: (image?.id && String(image.id)) || null
      };
    }

    return { ...value };
  }
}
