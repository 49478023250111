import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { EntityType } from '../../enums';

@Component({
  selector: 'ct-repost-entity',
  templateUrl: './repost-entity.component.html',
  styleUrls: ['./repost-entity.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RepostEntityComponent {
  @Input() entityType: EntityType;
  @Input() entityId?: string;
  @Input() loading: boolean;
  @Output() repost = new EventEmitter<{ entityType: EntityType; entityId: string }>();

  onRepostToChannel(): void {
    this.repost.emit({
      entityType: this.entityType,
      entityId: this.entityId as string
    });
  }
}
