import { DOCUMENT } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { FormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogConfig } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthQuery } from '@ct/auth';
import {
  DeletionDialogComponent,
  DialogService,
  ImageUploadService,
  InformationDialogComponent,
  SelectOption,
  SpinnerService
} from '@ct/components';
import { DialogConfig } from '@ct/components/dialog/interfaces';
import {
  BlogPost,
  BlogPostStatus,
  DestroyableFeature,
  Features,
  FormStateDispatcher,
  GroupTimelineType,
  GroupWithStories,
  LocalStorageService,
  Series,
  Tag,
  UploadedImage,
  UserProfile
} from '@ct/core';
import {
  BaseAutosaveFormComponent,
  BlogPostApiService,
  GroupApiService,
  ImageMetadata,
  Mode,
  MyAccountPhotoApiService,
  SeriesApiService,
  ShareApiService,
  TagApiService,
  toSlug,
  YoutubeVideo,
  YoutubeVideoAddService,
  YoutubeVideoApiService
} from '@ct/shared';
import { NotificationQuery } from '@ct/shared/services/notification-state';
import { TranslateService } from '@ngx-translate/core';
import { QuillModules } from 'ngx-quill';
import { forkJoin, Observable, of, throwError } from 'rxjs';
import { catchError, filter, finalize, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';

import { PublishStoryDialogComponent } from '../publish-story-dialog';

const QUILL_MODULES_CONFIG: QuillModules = {
  toolbar: [[{ header: [1, 2, 3, 4, 5, 6, false] }], ['bold', 'italic', { list: 'bullet' }, 'link', 'image']]
};

const MAX_BODY_LENGTH = 7 * 1000 * 1000;

const BLOG_POST_AUTOSAVE_CONFIG = {
  autosaveKey: 'BLOG_POST_CREATE_AUTOSAVE',
  excludeFormFields: ['status'],
  dialogConfig: {
    titleKey: 'MY_ACCOUNT.MY_POSTS_FEATURE.AUTOSAVE_DIALOG.TITLE',
    messageKey: 'MY_ACCOUNT.MY_POSTS_FEATURE.AUTOSAVE_DIALOG.MESSAGE',
    confirmKey: 'MY_ACCOUNT.MY_POSTS_FEATURE.AUTOSAVE_DIALOG.CONFIRM',
    cancelKey: 'MY_ACCOUNT.MY_POSTS_FEATURE.AUTOSAVE_DIALOG.CANCEL'
  }
};

@Component({
  selector: 'ct-my-account-post-create',
  templateUrl: './my-account-post-create.component.html',
  styleUrls: ['./my-account-post-create.component.scss'],
  providers: [FormStateDispatcher],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@Features([DestroyableFeature()])
export class MyAccountPostCreateComponent extends BaseAutosaveFormComponent implements OnInit {
  public readonly destroyed$: Observable<void>;

  public modules: QuillModules = QUILL_MODULES_CONFIG;
  public isLoading = false;
  public groups: GroupWithStories[] = [];
  public seriesList: SelectOption[] = [];
  protected readonly sharedGroupNames = new Set<string | undefined>();

  public loggedInUser$ = this.authQuery.profile$ as Observable<UserProfile>;
  public readonly notifications$ = this.notificationQuery.selectNotifications$;

  public readonly form = new UntypedFormGroup({
    id: new UntypedFormControl(),
    title: new UntypedFormControl('', [Validators.required]),
    slug: new UntypedFormControl(''),
    tags: new UntypedFormControl([]),
    featuredPhoto: new UntypedFormControl(''),
    featuredPhotoTitle: new UntypedFormControl(''),
    featuredPhotoKeywords: new UntypedFormControl(''),
    featuredYoutubeVideo: new UntypedFormControl(''),
    body: new UntypedFormControl('', [Validators.required, Validators.maxLength(MAX_BODY_LENGTH)]),
    status: new UntypedFormControl(BlogPostStatus.Draft),
    photos: new UntypedFormControl([]),
    videos: new FormArray([]),
    youtubeVideos: new FormArray([]),
    seriesTitle: new UntypedFormControl(),
    seriesId: new UntypedFormControl(),
    allowComments: new UntypedFormControl(true)
  });

  get post() {
    return this.route.snapshot.data.post;
  }

  get status() {
    return this.form.controls.status?.value;
  }

  get photos() {
    return this.form.controls.photos?.value;
  }

  get isDraft() {
    return this.status === BlogPostStatus.Draft;
  }

  get isPublishedPrivately() {
    return this.status === BlogPostStatus.PublishedPrivately;
  }

  get isPublishedToEveryone() {
    return this.status === BlogPostStatus.Published;
  }

  get isPublished() {
    return [BlogPostStatus.Published, BlogPostStatus.PublishedPrivately].includes(this.status);
  }

  get isWaitingForReview() {
    return this.status === BlogPostStatus.WaitingApproval;
  }

  get requiresAction() {
    return this.status === BlogPostStatus.Suspended || this.status === BlogPostStatus.FlaggedForReview;
  }

  get isEditMode() {
    return this.route.snapshot.data?.mode === Mode.Edit;
  }

  public readonly optionsFn = (match: string) => this.tagApiService.getAll(match);

  public readonly createFn = (name: string) => this.tagApiService.create(name);

  public readonly checkFn = (name: string) => this.tagApiService.getByName(name);

  public readonly labelFn = ({ name }: Tag) => name as string;

  constructor(
    protected localStorageService: LocalStorageService,
    protected dialogService: DialogService,
    protected translateService: TranslateService,
    private formState: FormStateDispatcher,
    private route: ActivatedRoute,
    private router: Router,
    @Inject(DOCUMENT) private document: any,
    private changeDetectorRef: ChangeDetectorRef,
    private spinnerService: SpinnerService,
    private shareApiService: ShareApiService,
    private seriesApiService: SeriesApiService,
    private imageUploadService: ImageUploadService,
    private blogPostApiService: BlogPostApiService,
    private tagApiService: TagApiService,
    private myAccountPhotoApiService: MyAccountPhotoApiService,
    private snackBar: MatSnackBar,
    private youtubeVideoAddService: YoutubeVideoAddService,
    private youtubeVideoApiService: YoutubeVideoApiService,
    private groupApiService: GroupApiService,
    private authQuery: AuthQuery,
    private notificationQuery: NotificationQuery
  ) {
    super(localStorageService, dialogService, translateService, BLOG_POST_AUTOSAVE_CONFIG);
  }

  ngOnInit() {
    this.loadSeriesList();
    if (this.isEditMode && this.post) {
      this.loadPostAndPhotos();
    } else {
      this.setForm(this.form);
      this.getAutosavedItem()
        .pipe(take(1))
        .subscribe((autosavedPost) => {
          if (!autosavedPost) {
            this.stopAutosave();
            this.startAutosave();
            return;
          }
          this.form.patchValue(autosavedPost);
          this.startAutosave();
        });
    }
    this.generateSlugFromTitle();
  }

  loadPostAndPhotos() {
    const photosRequest$ = this.post.photoIds?.length
      ? this.myAccountPhotoApiService.getByIds(this.post.photoIds)
      : of([]);
    photosRequest$
      .pipe(map((photos) => ({ ...this.post, photos: photos.map((photo) => ({ ...photo, id: String(photo?.id) })) })))
      .subscribe(({ disableComments, ...post }) => {
        this.form.patchValue({ ...post, allowComments: !disableComments });
        if (this.post.youtubeVideos) {
          for (const video of this.post.youtubeVideos) {
            this.youtubeVideos.push(new UntypedFormControl(video));
          }
        }
      });

    this.getUserGroups();
  }

  generateSlugFromTitle() {
    this.form.controls.title.valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe((title) => this.form.controls.slug.patchValue(toSlug(title)));
  }

  onSwitchDraft() {
    this.createOrUpdate(BlogPostStatus.Draft);
  }

  onUpdate() {
    this.createOrUpdate();
  }

  submitForApproval() {
    this.createOrUpdate(BlogPostStatus.WaitingApproval);
  }

  onSaveDraft() {
    this.createOrUpdate(BlogPostStatus.Draft);
  }

  onPublish() {
    const dialogRef = this.dialogService.open(PublishStoryDialogComponent, {
      data: { story: this.form.value, groups: this.groups },
      width: '30vw',
      minWidth: '400px'
    });
    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe((result: { toAdd: string[]; toRemove: string[]; isPublic: boolean }) => {
        if (!result) {
          return;
        }
        this.createOrUpdatePost(
          result.isPublic ? BlogPostStatus.Published : BlogPostStatus.PublishedPrivately
        ).subscribe({
          next: (post) => {
            if (result?.toAdd.length || result?.toRemove.length) {
              forkJoin([
                ...result.toAdd.map((groupId) =>
                  this.groupApiService.addToTimeline(groupId, { type: GroupTimelineType.Story, entityId: post.id })
                ),
                ...result.toRemove.map((groupId) =>
                  this.groupApiService.removeEntityFromTimeline(groupId, post.id as string)
                )
              ]).subscribe(() => {
                this.getUserGroups();
                this.navigateToView(post);
              });
            } else {
              this.navigateToView(post);
            }
          },
          error: () => {
            const snackbarMessage = this.translateService.instant('COMMON.FAIL_TO_SAVE');
            const close = this.translateService.instant('COMMON.CLOSE');
            this.snackBar.open(snackbarMessage, close, { duration: 10000 });
          }
        });
      });
  }

  createOrUpdatePost(status?: BlogPostStatus): Observable<BlogPost> {
    this.formState.onSubmit.notify();

    if (this.form.invalid) {
      this.scrollToFirstError();
      return throwError('Invalid form');
    }

    this.isLoading = true;
    this.spinnerService.show();
    this.changeDetectorRef.markForCheck();

    const { id, allowComments, featuredPhotoTitle, featuredPhotoKeywords, seriesTitle, featuredPhoto, ...postForm } =
      this.form.value;
    postForm.youtubeVideos = postForm.youtubeVideos.filter((v: string | null) => !!v);
    const blogPostPayload = {
      ...postForm,
      disableComments: !allowComments,
      featuredPhoto: featuredPhoto ? featuredPhoto : { id: null }
    };
    if (status) {
      blogPostPayload.status = status;
    }

    const action$: Observable<Series> = seriesTitle
      ? this.seriesApiService.create({ title: seriesTitle })
      : (of(null) as unknown as Observable<Series>);

    return action$.pipe(
      switchMap((series: Series) => {
        if (series) {
          blogPostPayload.seriesId = series?.id;
        }
        return !id
          ? this.blogPostApiService.create(blogPostPayload)
          : this.blogPostApiService.update(id, blogPostPayload);
      }),
      tap((post) => {
        this.form.patchValue({ ...post, seriesTitle: null });
      }),
      switchMap((post) => {
        if (!blogPostPayload.featuredPhotoId) {
          return of(post);
        }
        const updatedMetadata: ImageMetadata = {
          photoTitle: featuredPhotoTitle,
          keywords: featuredPhotoKeywords
        };
        return this.myAccountPhotoApiService
          .updateMetadata(blogPostPayload.featuredPhotoId, updatedMetadata)
          .pipe(map(() => post));
      }),
      finalize(() => {
        this.isLoading = false;
        this.spinnerService.hide();
        this.changeDetectorRef.markForCheck();
      })
    );
  }

  createOrUpdate(status?: BlogPostStatus) {
    return this.createOrUpdatePost(status).subscribe({
      next: (post) => this.navigateToView(post),
      error: () => {
        const snackbarMessage = this.translateService.instant('COMMON.FAIL_TO_SAVE');
        const close = this.translateService.instant('COMMON.CLOSE');
        this.snackBar.open(snackbarMessage, close, { duration: 10000 });
      }
    });
  }

  onDelete() {
    const title = this.translateService.instant('MY_ACCOUNT.MY_POSTS_FEATURE.DELETE_POST_TITLE');
    const message = this.translateService.instant('MY_ACCOUNT.MY_POSTS_FEATURE.DELETE_POST_MESSAGE');
    const { id } = this.form.value;
    this.dialogService
      .open(DeletionDialogComponent, {
        data: {
          title,
          message
        },
        width: '50vw'
      })
      .afterClosed()
      .pipe(
        take(1),
        filter(Boolean),
        switchMap(() =>
          this.blogPostApiService.remove(id).pipe(
            catchError((err) => {
              console.log(err);
              if (err.status === 403) {
                return throwError(err);
              }
              return of(null);
            })
          )
        )
      )
      .subscribe(
        () => this.navigateToViewList(),
        () => {
          const snackbarMessage = this.translateService.instant('MY_ACCOUNT.MY_POSTS_FEATURE.FORBIDDEN_DELETE_POST');
          const close = this.translateService.instant('COMMON.CLOSE');
          this.snackBar.open(snackbarMessage, close, { duration: 10000 });
        }
      );
  }

  onSelectFeaturedPhotoPlaceholder() {
    this.imageUploadService
      .showUploadDialog({
        titleKey: 'MY_ACCOUNT.MY_POSTS_FEATURE.ADD_FEATURED_PHOTO',
        multiple: false,
        selectable: true,
        firstTabKey: 'MY_ACCOUNT.UPLOAD_PHOTO',
        secondTabKey: 'MY_ACCOUNT.MY_PHOTOS',
        getAllImagesFn: () => this.myAccountPhotoApiService.getAllMyPhotos(),
        uploadFn: (file: File) =>
          this.myAccountPhotoApiService.uploadWithProgress(file).pipe(
            tap(() => this.spinnerService.show()),
            finalize(() => this.spinnerService.hide())
          )
      })
      .afterClosed()
      .pipe(
        take(1),
        filter(([photo]) => !!photo),
        switchMap(([photo]) =>
          this.myAccountPhotoApiService.getMetadata(photo?.id).pipe(
            tap(() => this.spinnerService.show()),
            map((metadata) => ({ metadata, photo })),
            finalize(() => this.spinnerService.hide())
          )
        )
      )
      .subscribe(({ photo, metadata }) => {
        this.form.controls.featuredPhoto.patchValue(photo);
        this.form.controls.featuredPhotoTitle.patchValue(metadata?.photoTitle);
        this.form.controls.featuredPhotoKeywords.patchValue(metadata?.keywords);
        this.changeDetectorRef.detectChanges();
      });
  }

  onReplaceFeaturedPhoto() {
    this.onSelectFeaturedPhotoPlaceholder();
  }

  onRemoveFeaturedPhoto() {
    this.form.controls.featuredPhoto.patchValue(undefined);
  }

  onAddPhotos() {
    this.imageUploadService
      .showUploadDialog({
        titleKey: 'MY_ACCOUNT.ADD_PHOTOS',
        multiple: true,
        selectable: true,
        firstTabKey: 'MY_ACCOUNT.UPLOAD_PHOTOS',
        secondTabKey: 'MY_ACCOUNT.MY_PHOTOS',
        getAllImagesFn: () => this.myAccountPhotoApiService.getAllMyPhotos(),
        uploadFn: (file: File) => this.myAccountPhotoApiService.uploadWithProgress(file)
      })
      .afterClosed()
      .pipe(take(1))
      .subscribe((photos) => {
        this.form.controls.photos.patchValue([...this.form.controls.photos.value, ...photos]);
        this.changeDetectorRef.detectChanges();
      });
  }

  onRemovePhoto({ id }: UploadedImage) {
    const photos = this.photos.filter((photo: UploadedImage) => photo?.id !== id);
    this.form.controls.photos.patchValue(photos);
    this.changeDetectorRef.detectChanges();
  }

  navigateToView(post: BlogPost) {
    this.form.patchValue({ id: post?.id });
    return this.dialogService
      .open(InformationDialogComponent, {
        data: {
          title: this.translateService.instant('MY_ACCOUNT.MY_POSTS_FEATURE.SAVED_POST_TITLE'),
          message: this.translateService.instant('MY_ACCOUNT.MY_POSTS_FEATURE.SAVED_POST_MESSAGE'),
          cancel: this.translateService.instant('MY_ACCOUNT.MY_POSTS_FEATURE.SAVED_POST_CANCEL'),
          confirm: this.translateService.instant('MY_ACCOUNT.MY_POSTS_FEATURE.SAVED_POST_NAVIGATE')
        },
        width: '450px'
      } as MatDialogConfig<DialogConfig>)
      .afterClosed()
      .pipe(filter(Boolean))
      .subscribe(() => {
        this.stopAutosave();
        this.router.navigate([this.isEditMode ? '../../view' : '../view', post?.id, post?.slug], {
          relativeTo: this.route
        });
      });
  }

  navigateToViewList() {
    this.stopAutosave();
    this.router.navigate(['/my-account/writings']);
  }

  get youtubeVideos() {
    return this.form.get('youtubeVideos') as FormArray;
  }

  onAddVideos() {
    this.youtubeVideoAddService
      .showVideoAddDialog({
        titleKey: 'MY_ACCOUNT.ADD_VIDEOS',
        selectable: true,
        firstTabKey: 'MY_ACCOUNT.UPLOAD_VIDEO',
        secondTabKey: 'MY_ACCOUNT.MY_VIDEOS',
        getAllVideosFn: () => this.youtubeVideoApiService.getAllMyVideos(),
        addVideoLinkFn: (link: string) => {
          return of({ youtubeId: link });
        }
      })
      .afterClosed()
      .pipe(take(1))
      .subscribe((videos) => {
        for (const video of videos) {
          this.youtubeVideos.push(new UntypedFormControl(video.youtubeId));
        }
        this.changeDetectorRef.detectChanges();
      });
  }

  removeVideo(video: YoutubeVideo) {
    this.youtubeVideos.removeAt(this.youtubeVideos.value.findIndex((videoId: string) => videoId === video.youtubeId));
  }

  private scrollToFirstError() {
    setTimeout(() => {
      const error = this.document.querySelector('.mat-error');
      error?.scrollIntoView({ block: 'center', inline: 'nearest' });
    });
  }

  private getUserGroups() {
    this.spinnerService.show();
    this.shareApiService
      .getGroupsWithStories()
      .pipe(finalize(() => this.spinnerService.hide()))
      .subscribe((groups) => {
        this.groups = groups;
        const id = String(this.post.id);
        this.groups.forEach((group) => {
          if (group.sharedBlogPosts?.includes(id)) {
            this.sharedGroupNames.add(group.title);
          }
        });
        this.changeDetectorRef.detectChanges();
      });
  }

  private loadSeriesList() {
    this.spinnerService.show();
    const user = this.authQuery.profile;
    this.seriesApiService
      .getAll({ authorId: user?.userId })
      .pipe(finalize(() => this.spinnerService.hide()))
      .subscribe((series) => {
        this.seriesList = series.map(({ id, title }) => ({
          value: id as string,
          label: title as string
        }));
        this.changeDetectorRef.markForCheck();
      });
  }
}
