import { Group } from './group.interface';
import { UploadedImage } from './uploaded-image.interface';
import { UserProfile } from './user-profile.interface';

export enum ChannelLinkType {
  Url = 'url',
  Header = 'header'
}

export class ChannelLink {
  constructor(
    public readonly label: string,
    public readonly url: string,
    public readonly type: ChannelLinkType = ChannelLinkType.Url
  ) {}
}

export interface VideoChannelEntity extends Group {
  readonly id?: string;
  readonly handle?: string;
  readonly bannerImageId?: string;
  readonly modifiedOn?: Date;
  readonly avatarImage?: UploadedImage;
  readonly bannerImage?: UploadedImage;
  readonly author?: UserProfile;
  readonly googleCalendarPublicUrl?: string;
  readonly bannerImageSize?: 'small' | 'large';
  readonly links?: ChannelLink[];
  readonly socialLinks?: ChannelLink[];
}

export interface ParseLinktreeResponse {
  readonly socialLinks: ChannelLink[];
  readonly links: ChannelLink[];
}
