import { Environment } from './environment.interface';

export const environment: Environment = {
  production: true,
  enableServiceWorker: true,
  environment: 'test',
  apiBaseUrl: 'https://api-test.camping.tools/api',
  identityApiBaseUrl: 'https://api-test.camping.tools/api/user/identity',
  userRelationsApiBaseUrl: 'https://api-test.camping.tools/api/user-relations',
  userProfileApiBaseUrl: 'https://api-test.camping.tools/api/user',
  merchantProfileApiBaseUrl: 'https://api-test.camping.tools/api/merchant',
  marketplaceApiBaseUrl: 'https://api-test.camping.tools/api/marketplace',
  shareApiBaseUrl: 'https://api-test.camping.tools/api/share',
  reportsApiBaseUrl: 'https://api-test.camping.tools/api/reports',
  emailApiBaseUrl: 'https://api-test.camping.tools/api/email-api',
  fileUploadApiBaseUrl: 'https://api-test.camping.tools/api/file-upload',
  blogApiBaseUrl: 'https://api-test.camping.tools/api/blog',
  seriesApiBaseUrl: 'https://api-test.camping.tools/api/series',
  threadApiBaseUrl: 'https://api-test.camping.tools/api/threads',
  listsApiBaseUrl: 'https://api-test.camping.tools/api/lists',
  listItemsApiBaseUrl: 'https://api-test.camping.tools/api/list-items',
  groupApiBaseUrl: 'https://api-test.camping.tools/api/groups',
  tripApiBaseUrl: 'https://api-test.camping.tools/api/trips',
  exploreApiBaseUrl: 'https://api-test.camping.tools/api/explore',
  itineraryApiBaseUrl: 'https://api-test.camping.tools/api/itinerary',
  likeApiBaseUrl: 'https://api-test.camping.tools/api/likes',
  notificationsApiBaseUrl: 'https://api-test.camping.tools/api/notifications',
  journalEntryApiBaseUrl: 'https://api-test.camping.tools/api/journal-entries',
  tagApiBaseUrl: 'https://api-test.camping.tools/api/tags',
  videoApiBaseUrl: 'https://api-test.camping.tools/api/video',
  channelApiBaseUrl: 'https://api-test.camping.tools/api/channel',
  searchApiBaseUrl: 'https://api-test.camping.tools/api/search',
  adminUserProfileApiBaseUrl: 'https://admin-backend-prod-dot-test-camping-tools.ue.r.appspot.com/api/user',
  adminBlogApiBaseUrl: 'https://admin-backend-prod-dot-test-camping-tools.ue.r.appspot.com/api/blog',
  adminEmailApiBaseUrl: 'https://admin-backend-prod-dot-test-camping-tools.ue.r.appspot.com/api/email-api',
  adminRolesApiBaseUrl: 'https://admin-backend-prod-dot-test-camping-tools.ue.r.appspot.com/api/roles',
  adminGroupsApiBaseUrl: 'https://admin-backend-prod-dot-test-camping-tools.ue.r.appspot.com/api/groups',
  aiChatApiBaseUrl: 'https://mean-seals-argue.loca.lt/v1/chat/completions',
  transcodedVideosBucket: 'transcoding-output-test',
  reCaptchaSiteKey: '6Lf4stEaAAAAADjeNInBuKhjPMRmcIw8o1LAWvun',
  googleMapsApiKey: 'AIzaSyB-AtzlOr2wq63nNuQ8_-_pGM_DI5C7QL4',
  gaTrackingId: '',
  gtmId: '',
  activeCampaignId: '',
  fbPixelId: '',
  webUrl: 'https://test.camping.tools',
  staticUrl: 'https://static-test.camping.tools',
  sentry: {
    dsn: 'https://13210badf472430db45d8eac6f077b7d@o1132589.ingest.sentry.io/6194938',
    sampleRate: 1,
    tracesSampleRate: 0,
    tracingOrigins: ['test.camping.tools', 'api-test.camping.tools'],
    environment: 'test',
    enabled: true
  },
  firebaseConfig: {
    apiKey: 'AIzaSyCnU4vKj-oLoMxopilHcGkZ9Q6OyYi6sbk',
    authDomain: 'test-camping-tools.firebaseapp.com',
    projectId: 'test-camping-tools',
    storageBucket: 'test-camping-tools.appspot.com',
    messagingSenderId: '874343519904',
    appId: '1:874343519904:web:10a8bfd2a88bcc3563dbc5',
    measurementId: 'G-8HMVH1LV86',
    vapidKey: 'BOh34YKwiYrCOIf3hqXawzbpv55SnlRKUiAd62Cv3QY6jqZcZca5ilDg7k2GwyDT9js74IimQs9B0_Z45DZxeM0'
  },
  opentelemetry: {
    application: {
      name: 'test.app.retailer',
      version: 'beta'
    },
    tracing: {
      enabled: false,
      propagate: ['/api', 'https://api-test.camping.tools'],
      whitelist: ['/api', 'https://api-test.camping.tools'],
      url: 'https://otel-node-prod-dot-prod-camping-tools.uc.r.appspot.com/v1/traces'
    },
    metrics: {
      enabled: false,
      url: 'https://otel-node-prod-dot-prod-camping-tools.uc.r.appspot.com/v1/metrics'
    }
  }
};
