import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SpinnerModule } from '@ct/components';
import { AuthorModule } from '@ct/components/author';
import { CardWrapperModule } from '@ct/components/card-wrapper';
import { IconModule } from '@ct/components/icon';
import { ImageModule } from '@ct/components/image';
import { LinkModule } from '@ct/components/link';
import { TagModule } from '@ct/components/tag';
import { TextEditorModule } from '@ct/components/text-editor';
import { PipesModule } from '@ct/core';
import { TranslateModule } from '@ngx-translate/core';

import { RepostEntityModule } from '../../modules/repost-entity';
import { MediaSharedModule } from '../media-shared';
import { VideoItemModule } from '../video-item';
import {
  CampsiteEntryComponent,
  JournalEntryComponent,
  TripCardComponent,
  TripComponent,
  TripEntryComponent,
  TripEntryTabComponent,
  TripEntryTabsComponent
} from './components';

@NgModule({
  declarations: [
    TripComponent,
    TripCardComponent,
    TripEntryComponent,
    TripEntryTabsComponent,
    TripEntryTabComponent,
    CampsiteEntryComponent,
    JournalEntryComponent
  ],
  imports: [
    CommonModule,
    AuthorModule,
    TagModule,
    PipesModule,
    LinkModule,
    TranslateModule,
    ImageModule,
    IconModule,
    CardWrapperModule,
    TextEditorModule,
    MediaSharedModule,
    SpinnerModule,
    VideoItemModule,
    RepostEntityModule
  ],
  exports: [
    TripComponent,
    TripCardComponent,
    TripEntryComponent,
    TripEntryTabsComponent,
    TripEntryTabComponent,
    CampsiteEntryComponent,
    JournalEntryComponent
  ]
})
export class TripSharedModule {}
