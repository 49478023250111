import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthQuery } from '@ct/auth';
import {
  DestroyableFeature,
  EnrichedExploreTimeline,
  Features,
  Group,
  GroupTimeline,
  GroupTimelineType,
  TitleConfig,
  TitleFeature,
  UserProfile
} from '@ct/core';
import {
  EntityType,
  getCroppedThumbPublicUrl,
  GroupApiService,
  JournalEntryApiService,
  LikeApiService,
  trackById,
  TripJournalEntry
} from '@ct/shared';
import { NotificationQuery } from '@ct/shared/services/notification-state';
import { Observable, of } from 'rxjs';
import { finalize, map, switchMap, take } from 'rxjs/operators';

import { MY_ACCOUNT_GROUPS_FEATURE_LIMIT } from '../../my-account/constants';

interface PhotoQueryParams {
  offset: number;
}

const DEFAULT_OFFSET = 0;

@Component({
  selector: 'ct-channel-timeline',
  templateUrl: './channel-timeline.component.html',
  styleUrls: ['./channel-timeline.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@Features([DestroyableFeature(), TitleFeature()])
export class ChannelTimelineComponent implements OnInit {
  public readonly destroyed$: Observable<void>;
  public titleConfig: TitleConfig = {
    titleKey: 'MAIN.FEATURES.MY_ACCOUNT_PHOTOS'
  };
  public readonly limit = MY_ACCOUNT_GROUPS_FEATURE_LIMIT;
  public readonly timelineType = GroupTimelineType;
  public readonly getCroppedThumbPublicUrl = getCroppedThumbPublicUrl;

  public groupTimelines: GroupTimeline[] = [];
  public group: Group;
  public loading = false;
  public showLoadButton = true;
  public multiselect = false;

  public queryParams: PhotoQueryParams = {
    offset: DEFAULT_OFFSET
  };

  public loggedInUser$ = this.authQuery.profile$ as Observable<UserProfile>;
  public readonly notifications$ = this.notificationQuery.selectNotifications$;

  public trackByFn = trackById;
  activeCommentsEntityId: string | null;

  get isMember(): boolean {
    // const profile = this.authQuery.profile as UserProfile;
    // return (
    //   Boolean(this.group) &&
    //   Boolean(profile) &&
    //   Boolean(
    //     profile.groupIds?.includes(String(this.group.id)) ||
    //       profile.pendingInvitationGroupIds?.includes(String(this.group.id)) ||
    //     this.group.adminIds?.includes(profile?.userId as string)
    //   )
    // );

    return true;
  }

  get isAdmin(): boolean {
    const profile = this.authQuery.profile as UserProfile;

    return Boolean(this.group) && Boolean(profile) && Boolean(this.group.adminIds?.includes(profile?.userId as string));
  }

  get canPost(): boolean {
    return this.isAdmin;
    // const profile = this.authQuery.profile as UserProfile;
    // return this.isAdmin || Boolean(this.group) && Boolean(profile) && Boolean(profile.groupIds?.includes(String(this.group.id)));
  }

  constructor(
    private route: ActivatedRoute,
    private changeDetectorRef: ChangeDetectorRef,
    private groupApiService: GroupApiService,
    private likeApiService: LikeApiService,
    private journalEntryApiService: JournalEntryApiService,
    private authQuery: AuthQuery,
    private notificationQuery: NotificationQuery
  ) {}

  ngOnInit() {
    this.route.data.pipe(take(1)).subscribe(({ timeline, group }) => {
      this.groupTimelines = timeline;
      this.group = group;
    });
    this.route.queryParams.pipe(take(1)).subscribe(({ offset }) => {
      this.queryParams = {
        ...this.queryParams,
        offset: +offset || this.queryParams.offset
      };
      this.showLoadButton = !(this.groupTimelines.length === 0 || this.groupTimelines.length < this.limit);
    });
  }

  isAuthor(timeline: GroupTimeline): boolean {
    const profile = this.authQuery.profile as UserProfile;

    return Boolean(profile) && Boolean(timeline.authorId === profile?.userId);
  }

  deleteEntity(timeline: GroupTimeline): void {
    this.loading = true;
    this.groupApiService
      .removeTimeline(timeline.groupId, timeline.id as string)
      .pipe(
        switchMap(() => {
          this.queryParams.offset = DEFAULT_OFFSET;
          return this.loadTimeline(this.queryParams);
        }),
        finalize(() => (this.loading = false))
      )
      .subscribe((groupTimelines) => this.refreshTimeline(groupTimelines));
  }

  onLike(entity: EnrichedExploreTimeline) {
    const entityType = this.getEntityType(entity);
    this.likeApiService
      .like({
        entityId: (entity.metadataId ?? entity.entityId) as string,
        entityType
      })
      .subscribe((likes) => {
        entity.metadata = {
          ...(entity.metadata ?? {}),
          likes
        };
        this.changeDetectorRef.markForCheck();
      });
  }

  onDislike(entity: EnrichedExploreTimeline) {
    this.likeApiService
      .dislike({
        entityId: (entity.metadataId ?? entity.entityId) as string,
        userId: this.authQuery.profile?.userId as string
      })
      .subscribe((likes) => {
        entity.metadata = {
          ...(entity.metadata ?? {}),
          likes
        };
        this.changeDetectorRef.markForCheck();
      });
  }

  toggleComments(entity: EnrichedExploreTimeline) {
    this.activeCommentsEntityId === entity.id
      ? (this.activeCommentsEntityId = null)
      : (this.activeCommentsEntityId = entity?.id ?? null);
    this.changeDetectorRef.markForCheck();
  }

  getEntityType(entity: EnrichedExploreTimeline): EntityType {
    if (entity.groupId && entity.id === entity.metadataId) {
      return EntityType.Memory;
    }

    const type = entity.type;

    switch (type) {
      case GroupTimelineType.Document:
        return EntityType.Document;
      case GroupTimelineType.Video:
        return EntityType.Video;
      case GroupTimelineType.Trip:
        return EntityType.Trip;
      case GroupTimelineType.Story:
        return EntityType.Story;
      default:
        return EntityType.Memory;
    }
  }

  onScroll() {
    if (this.loading || !this.showLoadButton) {
      return false;
    }
    this.loading = true;
    this.queryParams.offset = this.queryParams.offset + this.limit;
    this.loadTimeline(this.queryParams).subscribe((groupTimelines) => this.refreshTimeline(groupTimelines));
  }

  loadTimeline({ offset }: PhotoQueryParams) {
    this.loading = true;
    return this.groupApiService
      .getTimeline(this.group.id as string, { range: { limit: this.limit, offset } })
      .pipe(finalize(() => (this.loading = false)));
  }

  refreshTimeline(groupTimelines: GroupTimeline[]) {
    if (this.queryParams.offset === DEFAULT_OFFSET) {
      this.groupTimelines = [...groupTimelines];
    } else {
      this.groupTimelines = [...this.groupTimelines, ...groupTimelines];
    }
    this.showLoadButton = !(groupTimelines.length === 0 || groupTimelines.length < this.limit);
    this.loading = false;
    this.changeDetectorRef.detectChanges();
  }

  onAddTimeline(timeline: Partial<GroupTimeline>) {
    this.loading = true;
    this.changeDetectorRef.detectChanges();
    let action$: Observable<Partial<GroupTimeline>>;

    if (timeline.type === GroupTimelineType.Memory && !timeline.entityId) {
      action$ = this.journalEntryApiService
        .create({ body: timeline.body, groupIds: [this.group.id as string] })
        .pipe(map((memory) => ({ ...timeline, body: undefined, entityId: memory.id })));
    } else {
      action$ = of(timeline);
    }

    action$
      .pipe(
        switchMap((data) => this.groupApiService.addToTimeline(this.group.id as string, data)),
        switchMap(() => {
          this.queryParams = {
            offset: DEFAULT_OFFSET
          };
          return this.loadTimeline(this.queryParams);
        })
      )
      .subscribe((groupTimelines) => this.refreshTimeline(groupTimelines));
  }

  toJournalEntry(entity: TripJournalEntry | undefined): TripJournalEntry {
    return entity as TripJournalEntry;
  }

  getTripLink(timeline: GroupTimeline): string[] {
    return ['/my-account/trips', timeline.entityId as string, timeline.trip?.slug as string, 'timeline'];
  }

  getStoryLink(timeline: GroupTimeline): string {
    const slug = timeline.story?.slug as string;
    const slugArray = slug.split('-');
    const id = slugArray[slugArray.length - 1];
    return [
      '/stories',
      id === String(timeline.entityId) ? (timeline.story?.slug as string) : `${slug}-${timeline.entityId}`
    ].join('/');
  }
}
