<ct-card-wrapper
  [item]="cardItem"
  [link]="link"
  [hasDateRange]="true"
  [isHeading]="isHeading"
  [noImagePlaceholderPath]="placeholderPath"
>
  <span *ngIf="trip" ctCardActions>
    <ct-icon *ngIf="canEdit" icon="edit" class="mr-2" title="edit" (click)="onEditClick()"></ct-icon>
    <ct-icon *ngIf="canEdit" icon="share" class="mr-2" title="share" (click)="onShareClick()"></ct-icon>
    <ct-repost-entity
      *ngIf="canEdit"
      class="mr-2"
      [entityId]="trip.id"
      [entityType]="entityType.Trip"
      (repost)="onRepostTrip($event)"
    ></ct-repost-entity>
    <ct-icon *ngIf="canOpen" icon="left-arrow-square" title="open" (click)="onOpenClick()"></ct-icon>
  </span>
  <ng-container *ngIf="trip" ctCardTitleSuffixInfo>
    <ng-content select="[ctTripCardExtraInfo]"></ng-content>
  </ng-container>
  <ng-container ctCardFooter>
    <ng-content select="[ctTripCardFooter]"></ng-content>
  </ng-container>
</ct-card-wrapper>
