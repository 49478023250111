import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseHttpService, HeaderType, RequestRange, UserProfile } from '@ct/core';
import { environment } from '@ct/environment';
import { Span } from '@ct/opentelemetry';
import { SetActiveOptions } from '@datorama/akita';
import { Observable } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';

import { AuthQuery, AuthStore, UserProfileStore } from './state';

const endpoint = environment.userProfileApiBaseUrl;

@Injectable({ providedIn: 'root' })
export class UserProfileApiService extends BaseHttpService {
  private profile$: Observable<UserProfile | null> = this.authQuery.select('profile');

  constructor(
    protected httpClient: HttpClient,
    protected store: UserProfileStore,
    protected authStore: AuthStore,
    protected authQuery: AuthQuery
  ) {
    super(httpClient, endpoint);
  }

  getById(id: string) {
    const headers = {
      [HeaderType.Accept]: 'application/json',
      [HeaderType.ContentType]: 'application/json'
    };
    return this.get(`profiles/${id}`, {}, { headers, withCredentials: true }).pipe(
      tap((user) => {
        this.store.upsert(user.userId, user);
      })
    );
  }

  getMyEmail(): Observable<string> {
    const headers = {
      [HeaderType.Accept]: 'application/json',
      [HeaderType.ContentType]: 'application/json'
    };
    return this.get(`profiles/my/email`, {}, { headers, withCredentials: true });
  }

  getByUserId(id: string) {
    const params = new HttpParams().append('userId', id);
    const headers = {
      [HeaderType.Accept]: 'application/json',
      [HeaderType.ContentType]: 'application/json'
    };

    this.store.setLoading(true);
    return this.get(`profiles`, params, { headers, withCredentials: true }).pipe(
      tap(([user]) => {
        this.store.upsert(user.userId, user);
        this.store.setLoading(false);
        this.setActive(id);
        if (this.authQuery.profile?.userId === user.userId) {
          this.updateAuthUserProfile(user).subscribe();
        }
      }),
      map(([user]) => user)
    );
  }

  setActive(id: string) {
    this.store.setActive(id as SetActiveOptions); // exactly same code doesn't require type cast in tag-api.service...
  }

  getByUsername(username: string) {
    const params = new HttpParams().append('username', username);
    const headers = {
      [HeaderType.Accept]: 'application/json',
      [HeaderType.ContentType]: 'application/json'
    };
    return this.get(`profiles`, params, { headers, withCredentials: true }).pipe(
      tap(([user]) => {
        this.store.upsert(user.userId, user);
      }),
      map(([data]) => data)
    );
  }

  create(userProfile: UserProfile) {
    const headers = {
      [HeaderType.Accept]: 'application/json',
      [HeaderType.ContentType]: 'application/json'
    };
    return this.post('profiles', userProfile, {}, { headers });
  }

  update(id: string, userProfile: Partial<UserProfile>) {
    const headers = {
      [HeaderType.Accept]: 'application/json',
      [HeaderType.ContentType]: 'application/json'
    };
    return this.patch(`profiles/${id}`, userProfile, {}, { headers });
  }

  search(search: string, range?: RequestRange) {
    return this.get(`profiles/search`, { query: search, ...range });
  }

  @Span()
  public updateAuthUserProfile(userProfile: UserProfile) {
    return this.profile$.pipe(
      take(1),
      tap((profile) => {
        this.authStore.update({ profile: { ...profile, ...userProfile } });
      })
    );
  }

  @Span()
  public resetUserProfileStore() {
    this.store.reset();
  }
}
