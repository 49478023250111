import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AuthQuery, AuthService } from '@ct/auth';
import { TabHeader } from '@ct/components';
import {
  FacebookCardType,
  Features,
  SeoConfig,
  SocialMediaMetaTagFeature,
  TitleFeature,
  TwitterCardType,
  UserProfile
} from '@ct/core';
import { NotificationQuery } from '@ct/shared/services/notification-state';
import { Observable, Subject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';

enum Channels {
  Subscriptions = 'subscriptions',
  MyChannels = 'my-channels',
  Public = 'public'
}

@Component({
  selector: 'ct-channel-list',
  templateUrl: './channel-list.component.html',
  styleUrls: ['./channel-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@Features([SocialMediaMetaTagFeature(), TitleFeature()])
export class ChannelListComponent {
  public readonly destroyed$: Subject<unknown> = new Subject();
  public seoConfig: SeoConfig = {
    titleConfig: {
      titleKey: 'MAIN.FEATURES.CHANNEL'
    },
    socialMediaConfig: {
      titleKey: 'MAIN.FEATURES.CHANNEL',
      descriptionKey: 'MAIN.DESCRIPTION',
      image: 'assets/previews/blog.jpg',
      facebookCardType: FacebookCardType.Website,
      twitterCardType: TwitterCardType.SummaryLargeImage
    }
  };

  get isLoggedIn$() {
    return this.authService.isAuthenticated();
  }

  public tabs: TabHeader[] = [
    { name: Channels.Subscriptions, labelKey: 'Subscriptions', selected: true },
    { name: Channels.MyChannels, labelKey: 'My Channels' },
    { name: Channels.Public, labelKey: 'All' }
  ];

  public loggedInUser$ = this.authQuery.profile$ as Observable<UserProfile>;
  public readonly notifications$ = this.notificationQuery.selectNotifications$;

  constructor(
    private router: Router,
    private authQuery: AuthQuery,
    private authService: AuthService,
    private notificationQuery: NotificationQuery
  ) {
    this.router.events
      .pipe(
        takeUntil(this.destroyed$),
        filter((event) => event instanceof NavigationEnd),
        map(() => {
          let route: ActivatedRoute = this.router.routerState.root;

          while (route.firstChild) {
            route = route.firstChild;
          }

          return route;
        })
      )
      .subscribe((route: ActivatedRoute) => {
        const path = route.snapshot.url[0]?.path;
        this.tabs = this.tabs.map((tab) => ({
          ...tab,
          selected: tab.name === path
        }));
      });
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  onFilterChanged(tab: TabHeader) {
    this.router.navigate(['channels', tab.name]);
  }
}
