import { isPlatformBrowser } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Inject,
  OnDestroy,
  OnInit,
  PLATFORM_ID
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AuthService } from '@ct/auth';
import {
  ChannelVideoUploadApiService,
  GoogleAnalyticsService,
  JsonLdService,
  MetaTagService,
  TitleService
} from '@ct/core';
import { environment } from '@ct/environment';
import { TranslateService } from '@ngx-translate/core';
import { Subject, timer } from 'rxjs';
import { distinctUntilChanged, filter, switchMap, take, takeUntil, tap } from 'rxjs/operators';

import { CookiesNoticeService } from '../cookies-notice';

const has = Object.prototype.hasOwnProperty;

@Component({
  selector: 'ct-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements AfterViewInit, OnInit, OnDestroy {
  get isLoggedIn$() {
    return this.authService.isAuthenticated();
  }

  get isTransparent() {
    return this.router.url === '/home' || this.router.url === '/marketplace';
  }

  @HostBinding('class.simple')
  get isSimple() {
    try {
      return (
        this.router.url.includes('/simple') ||
        (globalThis?.location && globalThis.location.pathname.includes('/simple'))
      );
    } catch (e) {
      return false;
    }
  }
  get shouldShowSearch() {
    try {
      return (
        !this.router.url.includes('/marketplace') ||
        (globalThis?.location && !globalThis.location.pathname.includes('/marketplace'))
      );
    } catch (e) {
      return true;
    }
  }

  private destroyed$ = new Subject<void>();

  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    private translateService: TranslateService,
    private titleService: TitleService,
    private cookiesNoticeService: CookiesNoticeService,
    private router: Router,
    private authService: AuthService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private metaTagService: MetaTagService,
    private jsonLdService: JsonLdService,
    private channelVideoUploadApiService: ChannelVideoUploadApiService
  ) {
    if (!environment.indexable) {
      this.metaTagService.addTag({
        name: 'robots',
        content: 'noindex'
      });
    }
    this.translateService
      .get('MAIN.TITLE')
      .pipe(take(1))
      .subscribe((title) => this.titleService.setDefaultSuffix(title));

    this.jsonLdService.insertSchema(JsonLdService.websiteSchema());

    this.metaTagService.addTags([
      {
        name: 'author',
        content: 'Camping Tools'
      },
      {
        name: 'geo.region',
        content: 'US'
      },
      {
        name: 'geo.position',
        content: '35.181692;-83.382623'
      },
      {
        name: 'ICBM',
        content: '35.181692,-83.382623'
      }
    ]);

    this.metaTagService.addKeywords();

    if (isPlatformBrowser(this.platformId) && !this.isSimple) {
      AppComponent.initTrackingScripts();
    }

    this.isLoggedIn$
      .pipe(
        distinctUntilChanged(),
        filter(Boolean),
        switchMap(() => this.authService.getUserProfile()),
        switchMap((user) =>
          this.channelVideoUploadApiService.getAll({
            range: { limit: 0, offset: 0 },
            adminId: user.userId,
            type: 'list'
          })
        )
      )
      .subscribe();
  }

  private static initTrackingScripts() {
    this.initGoogleTagManager();
    this.initGoogleAnalytics();
    this.initFbPixel();
    this.initActiveCampaignTracking();
    this.initActiveCampaignMailing();
  }

  private static initGoogleTagManager() {
    if (environment.gtmId) {
      // register google tag manager
      const gtmScript = document.createElement('script');
      gtmScript.innerHTML = `
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','${environment.gtmId}');
    `;
      document.head.appendChild(gtmScript);
    }
  }

  private static initGoogleAnalytics() {
    if (environment.gaTrackingId) {
      // register tag manager
      const gTagManagerScript = document.createElement('script');
      gTagManagerScript.async = true;
      gTagManagerScript.src = `https://www.googletagmanager.com/gtag/js?id=${environment.gaTrackingId}`;
      document.head.appendChild(gTagManagerScript);

      // register google analytics
      const gaScript = document.createElement('script');
      gaScript.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag() { dataLayer.push(arguments); }
      gtag('js', new Date());
      gtag('config', '${environment.gaTrackingId}');
    `;
      document.head.appendChild(gaScript);
    }
  }

  private static initFbPixel() {
    if (environment.fbPixelId) {
      // register Facebook Pixel
      const fbPixelScript = document.createElement('script');
      fbPixelScript.innerHTML = `
        !function(f,b,e,v,n,t,s)
        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
        n.queue=[];t=b.createElement(e);t.async=!0;
        t.src=v;s=b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t,s)}(window,document,'script',
        'https://connect.facebook.net/en_US/fbevents.js');
         fbq('init', '${environment.fbPixelId}');
        fbq('track', 'PageView');
    `;
      document.head.appendChild(fbPixelScript);
    }
  }

  private static initActiveCampaignTracking() {
    if (environment.activeCampaignId) {
      // register active campaign tracking
      const acScript = document.createElement('script');
      acScript.innerHTML = `
      (function(e,t,o,n,p,r,i){e.visitorGlobalObjectAlias=n;e[e.visitorGlobalObjectAlias]=e[e.visitorGlobalObjectAlias]||function(){(e[e.visitorGlobalObjectAlias].q=e[e.visitorGlobalObjectAlias].q||[]).push(arguments)};e[e.visitorGlobalObjectAlias].l=(new Date).getTime();r=t.createElement("script");r.src=o;r.async=true;i=t.getElementsByTagName("script")[0];i.parentNode.insertBefore(r,i)})(window,document,"https://diffuser-cdn.app-us1.com/diffuser/diffuser.js","vgo");
    vgo('setAccount', '${environment.activeCampaignId}');
    vgo('setTrackByDefault', true);
    vgo('process');
    `;
      document.head.appendChild(acScript);
    }
  }

  private static initActiveCampaignMailing() {
    // add popup to add email to mailing list
    const acScript = document.createElement('script');
    acScript.async = true;
    acScript.type = 'text/javascript';
    acScript.src = `https://campingtools.activehosted.com/f/embed.php?id=1`;
    document.head.appendChild(acScript);
  }

  ngOnInit() {
    if (environment.gaTrackingId && isPlatformBrowser(this.platformId)) {
      timer(500)
        .pipe(
          filter(() => has.call(window, 'ga')),
          take(1),
          switchMap(() => {
            return this.router.events.pipe(
              filter((e: any) => e instanceof NavigationEnd),
              tap((e: NavigationEnd) => {
                this.googleAnalyticsService.logPageView(e.url);
              })
            );
          }),
          takeUntil(this.destroyed$)
        )
        .subscribe();
    }
  }

  ngAfterViewInit() {
    if (isPlatformBrowser(this.platformId) && !this.isSimple) {
      this.cookiesNoticeService.showNotice();
    }
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
