import { inject, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthService } from '@ct/auth';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { ChannelComponent } from './channel';
import { ChannelListComponent } from './channel-list';
import {
  ChannelResolver,
  ChannelsAdminResolver,
  ChannelsResolver,
  ChannelsSubscriptionsResolver,
  ChannelVideosResolver,
  MyAccountGroupTimelineDataResolver
} from './resolvers';
import { UserChannelComponent } from './user-channel';
import { UserChannelsListComponent } from './user-channels-list';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'subscriptions',
    pathMatch: 'full',
    canMatch: [
      () =>
        inject(AuthService)
          .getUserProfile()
          .pipe(
            catchError(() => of(null)),
            map(Boolean)
          )
    ]
  },
  {
    path: '',
    redirectTo: 'my-channels',
    pathMatch: 'full',
    canMatch: [
      () =>
        inject(AuthService)
          .getUserProfile()
          .pipe(
            catchError(() => of(null)),
            map(Boolean)
          )
    ]
  },
  {
    path: '',
    redirectTo: 'public',
    pathMatch: 'full'
  },
  {
    path: '',
    component: ChannelListComponent,
    children: [
      {
        path: 'subscriptions',
        component: UserChannelsListComponent,
        resolve: {
          channels: ChannelsSubscriptionsResolver
        },
        canMatch: [() => inject(AuthService).getUserProfile().pipe(map(Boolean))]
      },
      {
        path: 'my-channels',
        component: UserChannelsListComponent,
        resolve: {
          channels: ChannelsAdminResolver
        },
        canMatch: [() => inject(AuthService).getUserProfile().pipe(map(Boolean))]
      },
      {
        path: 'public',
        component: UserChannelsListComponent,
        resolve: {
          channels: ChannelsResolver
        }
      }
    ]
  },
  {
    path: ':channelName',
    component: ChannelComponent,
    children: [
      {
        path: '',
        component: UserChannelComponent,
        resolve: {
          channel: ChannelVideosResolver,
          group: ChannelResolver,
          timeline: MyAccountGroupTimelineDataResolver
        }
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ChannelRoutingModule {}
