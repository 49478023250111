import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { map } from 'rxjs/operators';

import { ChannelsState, ChannelsStore } from './channels.store';

@Injectable({ providedIn: 'root' })
export class ChannelsQuery extends Query<ChannelsState> {
  selectChannels$ = this.select('channels');
  hasChannels$ = this.selectChannels$.pipe(map((channels) => channels?.length > 0));

  constructor(protected store: ChannelsStore) {
    super(store);
  }
}
