import { DOCUMENT } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, Renderer2 } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router, UrlSerializer } from '@angular/router';
import { SpinnerService } from '@ct/components/spinner';
import {
  CanonicalFeature,
  EMAIL_MAX_LENGTH,
  FacebookCardType,
  Features,
  FormStateDispatcher,
  PASSWORD_MAX_LENGTH,
  SeoConfig,
  SocialMediaMetaTagFeature,
  TitleFeature,
  TwitterCardType
} from '@ct/core';
import { FirebaseCloudMessagingService } from '@ct/shared/services/firebase-cloud-messaging.service';
import { switchMap, tap } from 'rxjs/operators';

import { AuthService } from '../../services';

@Component({
  selector: 'ct-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  providers: [FormStateDispatcher],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@Features([SocialMediaMetaTagFeature(), TitleFeature(), CanonicalFeature()])
export class LoginComponent implements OnDestroy {
  public readonly seoConfig: SeoConfig = {
    titleConfig: {
      titleKey: 'MAIN.FEATURES.LOGIN'
    },
    socialMediaConfig: {
      titleKey: 'MAIN.FEATURES.LOGIN',
      descriptionKey: 'MAIN.DESCRIPTION',
      image: 'assets/previews/login.jpg',
      facebookCardType: FacebookCardType.Website,
      twitterCardType: TwitterCardType.SummaryLargeImage
    },
    canonicalConfig: {
      canonicalUrl: '/login'
    }
  };
  public returnUrl: string;

  public readonly form = new UntypedFormGroup({
    email: new UntypedFormControl(null, [
      Validators.required,
      Validators.email,
      Validators.maxLength(EMAIL_MAX_LENGTH)
    ]),
    password: new UntypedFormControl(null, [Validators.required, Validators.maxLength(PASSWORD_MAX_LENGTH)])
  });

  public showError: boolean;

  constructor(
    private formState: FormStateDispatcher,
    private authService: AuthService,
    private firebaseCloudMessagingService: FirebaseCloudMessagingService,
    private router: Router,
    private route: ActivatedRoute,
    private changeDetectorRef: ChangeDetectorRef,
    private spinnerService: SpinnerService,
    private urlSerializer: UrlSerializer,
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2
  ) {
    this.returnUrl =
      this.route.snapshot.queryParams.returnUrl ?? this.route.snapshot.data.returnUrl ?? '/my-account/explore';
    this.renderer.addClass(this.document.body, 'hide-activecampaign');
  }

  ngOnDestroy() {
    this.renderer.removeClass(this.document.body, 'hide-activecampaign');
  }

  onLogIn() {
    this.formState.onSubmit.notify();

    if (this.form.invalid) {
      return;
    }

    this.spinnerService.show();

    this.authService
      .login(this.form.controls.email.value, this.form.controls.password.value)
      .pipe(
        switchMap(() => this.authService.getIdentity()),
        tap(() => this.authService.getUserProfile().subscribe())
      )
      .subscribe(
        async (identity) => {
          this.renderer.removeClass(this.document.body, 'hide-activecampaign');
          this.showError = false;
          this.firebaseCloudMessagingService.requestToken(identity?.guuid);
          await this.router.navigateByUrl(this.urlSerializer.parse(this.returnUrl));
          this.spinnerService.hide();
          this.changeDetectorRef.markForCheck();
        },
        () => {
          this.showError = true;
          this.spinnerService.hide();
          this.changeDetectorRef.markForCheck();
        }
      );
  }
}
