import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { ChannelVideoUploadApiService, VideoChannelEntity } from '@ct/core';

@Injectable({ providedIn: 'root' })
export class ChannelsSubscriptionsResolver implements Resolve<VideoChannelEntity[]> {
  constructor(private channelVideoUploadApiService: ChannelVideoUploadApiService) {}

  resolve() {
    return this.channelVideoUploadApiService.getChannelsSubscriptions();
  }
}
