import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { DialogService } from '@ct/components';
import { GroupTimeline, GroupTimelineType } from '@ct/core';
import { filter } from 'rxjs/operators';

import { SelectGroupEntityDialogComponent } from '../select-group-entity-dialog';

export type AddToTimeline = Pick<GroupTimeline, 'body' | 'entityId' | 'type'>;

interface SelectedEntity {
  id?: string;
  title?: string;
  name?: string;
  body?: string;
  path?: string;
  publicUrl?: string;
  xsPreview?: string;
  originalname?: string;
}

interface SelectResult {
  entity: SelectedEntity;
  entityType: GroupTimelineType;
}

@Component({
  selector: 'ct-channel-add-entry',
  templateUrl: './channel-add-entry.component.html',
  styleUrls: ['./channel-add-entry.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChannelAddEntryComponent {
  @Input() readonly channelId: string | undefined;
  @Output() addToTimeline = new EventEmitter<AddToTimeline>();

  readonly messageControl = new UntypedFormControl();
  selectedEntity?: SelectedEntity;
  selectedEntityType: GroupTimelineType | null;
  entityType = GroupTimelineType;

  constructor(private readonly dialogService: DialogService, private readonly cdr: ChangeDetectorRef) {}

  openShareDialog() {
    this.dialogService
      .open(SelectGroupEntityDialogComponent, {
        data: {
          isChannel: true,
          channelId: this.channelId
        }
      })
      .afterClosed()
      .pipe(filter<SelectResult>(Boolean))
      .subscribe(({ entity, entityType }: SelectResult) => {
        this.selectedEntity = entity;
        this.selectedEntityType = entityType;
        this.cdr.detectChanges();
      });
  }

  onSubmit() {
    if (!this.messageControl.value && !this.selectedEntity) {
      return;
    }

    this.addToTimeline.emit({
      body: this.messageControl.value,
      entityId: this.selectedEntity?.id?.toString(),
      type: this.selectedEntityType ?? GroupTimelineType.Memory
    });

    this.selectedEntity = undefined;
    this.messageControl.setValue(null);
    this.selectedEntityType = null;
  }

  onRemoveEntity() {
    this.selectedEntityType = null;
    this.selectedEntity = undefined;
  }
}
