<ct-dialog-content
  titleKey="Repost To Channel"
  [closeIcon]="true"
  headerIcon="repeat"
  [buttons]="buttons"
  [alignButtons]="'end'"
>
  <div class="row">
    <ct-select
      class="col-12"
      label="Select Channel"
      [showLabel]="true"
      [formControl]="channelsCtrl"
      [options]="channelsList$ | async"
    ></ct-select>
  </div>
</ct-dialog-content>
