import { DOCUMENT } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  Renderer2
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
  CanonicalConfig,
  CanonicalFeature,
  CustomValidators,
  DestroyableFeature,
  Features,
  FormStateDispatcher,
  TitleConfig,
  TitleFeature
} from '@ct/core';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { AuthApiService } from '../../services';

const RESEND_CODE_TIME = 60;

@Component({
  selector: 'ct-verify',
  templateUrl: './verify.component.html',
  styleUrls: ['./verify.component.scss'],
  providers: [FormStateDispatcher],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@Features([DestroyableFeature(), TitleFeature(), CanonicalFeature()])
export class VerifyComponent implements OnInit, OnDestroy {
  public readonly destroyed$: Observable<void>;

  public readonly titleConfig: TitleConfig = {
    titleKey: 'MAIN.FEATURES.VERIFY'
  };

  public readonly canonicalConfig: CanonicalConfig = {
    canonicalUrl: '/verify'
  };

  public form = new UntypedFormGroup({
    code: new UntypedFormControl('', [Validators.required, CustomValidators.verificationCode])
  });

  public isDisabledResend = false;
  public resendCodeTime = RESEND_CODE_TIME;

  get userId() {
    return this.route.snapshot.queryParams?.userId;
  }

  get username() {
    return this.route.snapshot.queryParams?.username;
  }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private changeDetectorRef: ChangeDetectorRef,
    private formState: FormStateDispatcher,
    private authApiService: AuthApiService,
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2
  ) {
    this.renderer.addClass(this.document.body, 'hide-activecampaign');
  }

  ngOnInit(): void {
    this.form.controls.code.valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => this.form.controls.code.setErrors(null));
  }

  ngOnDestroy() {
    this.renderer.removeClass(this.document.body, 'hide-activecampaign');
  }

  onVerify() {
    this.formState.onSubmit.notify();

    if (this.form.invalid) {
      return;
    }

    this.authApiService.verify(this.userId, this.form.value.code).subscribe(
      () => {
        this.renderer.removeClass(this.document.body, 'hide-activecampaign');

        this.router.navigate(['/login']);
      },
      (error) => (error?.status === 400 ? this.form.controls.code.setErrors({ codeHasExpiried: true }) : null)
    );
  }

  onRequestCode() {
    this.authApiService.resendVerificationCode(this.userId).subscribe(() => {
      this.isDisabledResend = true;
      this.changeDetectorRef.detectChanges();
    });
  }

  onCountdownFinished() {
    this.isDisabledResend = false;
    this.changeDetectorRef.detectChanges();
  }
}
