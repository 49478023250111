import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '@ct/auth';
import { SpinnerService } from '@ct/components';
import { BaseHttpService, HeaderType } from '@ct/core';
import { environment } from '@ct/environment';
import { SortOrder } from '@ct/shared';
import { Observable, of } from 'rxjs';
import { filter, finalize, switchMap, tap } from 'rxjs/operators';

import { MarketplaceItemFactory } from '../factories';
import {
  MarketplaceItem,
  MarketplaceItemSearchResults,
  MarketplaceItemsGetOptions,
  MarketplaceItemsSearchOptions
} from '../interfaces';
import { MarketplaceStore } from './state';

const endpoint = environment.marketplaceApiBaseUrl;

@Injectable({ providedIn: 'root' })
export class MarketplaceApiService extends BaseHttpService {
  constructor(
    protected httpClient: HttpClient,
    protected store: MarketplaceStore,
    private marketplaceItemFactory: MarketplaceItemFactory,
    private spinnerService: SpinnerService,
    private authService: AuthService
  ) {
    super(httpClient, endpoint);
  }

  getAll({
    range,
    condition,
    sortOrder = SortOrder.Desc,
    merchantId,
    categoryId,
    subCategoryId,
    type,
    sold
  }: MarketplaceItemsGetOptions): Observable<MarketplaceItem[]> {
    this.spinnerService.show();
    const headers = {
      [HeaderType.Accept]: 'application/json'
    };
    let params = new HttpParams();
    if (range?.limit !== undefined && range.limit !== null) {
      params = params.append('limit', range.limit as number);
    }
    if (range?.offset !== undefined && range.offset !== null) {
      params = params.append('offset', range.offset as number);
    }
    if (condition !== undefined && condition !== null) {
      params = params.append('condition', condition);
    }
    if (sortOrder !== undefined && sortOrder !== null) {
      params = params.append('sortOrder', sortOrder);
    }
    if (merchantId !== undefined && merchantId !== null) {
      params = params.append('merchantId', merchantId);
    }
    if (sold !== undefined && sold !== null) {
      params = params.append('sold', sold);
    }
    if (categoryId !== undefined && categoryId !== null) {
      params = params.append('categoryId', categoryId);
    }
    if (subCategoryId !== undefined && subCategoryId !== null) {
      params = params.append('subCategoryId', subCategoryId);
    }
    return this.get(`items`, params, { headers, withCredentials: true }).pipe(
      tap((items) => {
        if (type === 'list') {
          // save to store only for list requests.
          this.store.update({ items });
        }
      }),
      finalize(() => this.spinnerService.hide())
    );
  }

  getById(id: string): Observable<MarketplaceItem> {
    return this.get(`items/${id}`);
  }

  remove(id: string): Observable<undefined> {
    return this.delete(`items/${id}`);
  }

  create(item: MarketplaceItem) {
    const request = this.marketplaceItemFactory.toRequest(item);
    return this.post('items', request);
  }

  update(id: string, item: MarketplaceItem) {
    const request = this.marketplaceItemFactory.toRequest(item);
    return this.patch(`items/${id}`, request);
  }

  markAsSold(id: string, sold: boolean) {
    return this.patch(`items/${id}`, { sold });
  }

  getAllMyItems(options: MarketplaceItemsGetOptions): Observable<MarketplaceItem[]> {
    return this.authService.getIdentity().pipe(
      switchMap((identity) => {
        if (!identity) {
          return of([]);
        }
        return this.getAll({ ...options, merchantId: identity.guuid });
      })
    );
  }

  search({
    range,
    search,
    categoryId,
    subCategoryId,
    lat,
    lon,
    type,
    merchantId
  }: MarketplaceItemsSearchOptions): Observable<MarketplaceItemSearchResults> {
    let params = new HttpParams();
    if (range?.limit !== undefined && range.limit !== null) {
      params = params.append('limit', range.limit as number);
    }
    if (range?.offset !== undefined && range.offset !== null) {
      params = params.append('offset', range.offset as number);
    }
    if (search) {
      params = params.append('query', search as string);
    }
    if (categoryId) {
      params = params.append('categoryId', categoryId as string);
    }
    if (subCategoryId) {
      params = params.append('subCategoryId', subCategoryId as string);
    }
    if (lon) {
      params = params.append('lon', lon as string);
    }
    if (lat) {
      params = params.append('lat', lat as string);
    }
    if (merchantId) {
      params = params.append('merchantId', merchantId);
    }
    return this.get<MarketplaceItemSearchResults>(`items/search`, params).pipe(
      filter((result) => Boolean(result)),
      tap(({ results, otherResults }) => {
        if (type === 'list') {
          this.store.update({ items: results, otherItems: otherResults });
        }
      }),
      finalize(() => this.spinnerService.hide())
    );
  }
}
