import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MyAccountTripPhotoEditComponent } from '@ct/app-content/my-account/modules/my-account-trips/components';
import { FriendsQuery, UserRelationsApiService } from '@ct/app-content/my-account/services';
import { AuthQuery } from '@ct/auth';
import { DialogService } from '@ct/components';
import { ChannelsQuery, FriendStatus, RelationUserProfile, TitleService, UploadedImage } from '@ct/core';
import {
  AddToTripDialogComponent,
  getCroppedThumbPublicUrl,
  Mode,
  Trip,
  TripApiService,
  TripCampsite,
  TripEntityType,
  TripJournalEntry
} from '@ct/shared';
import { WritingJournalAddDialogComponent } from '@ct/shared/modules/add-to-writings-dialog';
import { CampsiteAddDialogComponent } from '@ct/shared/modules/trip-shared/modules/add-to-trip-dialog/components';
import { forkJoin, Observable, Subject } from 'rxjs';
import { filter, switchMap, take } from 'rxjs/operators';

import { CAMPISITE_ALL_FILTER } from '../../constants';

interface Element {
  id: string;
  type: TripEntityType | 'videoGrid';
  date: string;

  journal?: TripJournalEntry;
  campsite?: TripCampsite;
  photos?: UploadedImage[];
  video?: string;

  hasDate?: boolean;
  showTitle?: boolean;
  showMore?: boolean;
}

const LIMIT = 5;
const DEFAULT_OFFSET = 0;

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'ct-my-account-trip-timeline',
  templateUrl: './my-account-trip-timeline.component.html',
  styleUrls: ['./my-account-trip-timeline.component.scss']
})
export class MyAccountTripTimelineComponent implements OnInit, OnDestroy {
  elements: Element[];

  public readonly tripEntityType = TripEntityType;
  public readonly getCroppedThumbPublicUrl = getCroppedThumbPublicUrl;
  public loading = false;
  public showLoadButton = true;
  public offset = 0;
  public trip: Trip;
  public queryParams = {
    campsiteId: CAMPISITE_ALL_FILTER.id,
    offset: DEFAULT_OFFSET,
    filter: 'timeline'
  };

  get isAuthor(): boolean {
    return this.authQuery.getValue().profile?.userId === this.trip?.authorId;
  }
  public hasChannels$: Observable<boolean> = this.channelsQuery.hasChannels$;

  private friends$: Observable<RelationUserProfile[]> = this.friendsQuery.selectAll({
    filterBy: ({ friendStatus }) => friendStatus === FriendStatus.Accepted
  });

  private destroyed$ = new Subject();

  constructor(
    private route: ActivatedRoute,
    private titleService: TitleService,
    private tripApiService: TripApiService,
    private authQuery: AuthQuery,
    private friendsQuery: FriendsQuery,
    private channelsQuery: ChannelsQuery,
    private userRelationsApiService: UserRelationsApiService,
    private dialogService: DialogService,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    this.userRelationsApiService.getAllMyFriends().subscribe();
  }

  ngOnInit() {
    const timelineData = this.route.snapshot.data?.timeline;

    this.elements = timelineData?.timeline || [];
    this.trip = timelineData?.trip;

    if (this.elements.length === 0 || this.elements.length < LIMIT) {
      this.showLoadButton = false;
    }

    this.titleService.setTitle(this.trip?.title);
    this.enrichTimeline();
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  onScroll() {
    if (this.loading || !this.showLoadButton) {
      return false;
    }
    this.loading = true;
    return forkJoin({
      timeline: this.tripApiService.getTimeline(this.trip.id as string, {
        offset: this.offset + LIMIT,
        limit: LIMIT
      }),
      trip: this.tripApiService.getById(this.trip.id as string)
    }).subscribe((timelineData) => {
      this.offset += LIMIT;
      this.elements.push(...timelineData.timeline);
      if (timelineData.timeline.length === 0 || timelineData.timeline.length < LIMIT) {
        this.showLoadButton = false;
      }
      this.loading = false;
      this.enrichTimeline();
      this.changeDetectorRef.detectChanges();
    });
  }

  enrichTimeline() {
    this.elements = this.elements.map((element, index) => {
      if (this.elements[index - 1]) {
        element.hasDate = this.elements[index - 1].date?.slice(0, 10) !== element.date?.slice(0, 10);
      } else {
        element.hasDate = true;
      }
      if (element.type !== 'journal') {
        element.showTitle = true;
      }

      return element;
    });

    this.changeDetectorRef.markForCheck();
  }

  addDialog() {
    this.dialogService
      .open(AddToTripDialogComponent, { data: this.trip })
      .afterClosed()
      .pipe(
        take(1),
        filter((options) => options && Boolean(options.reload)),
        switchMap(() =>
          forkJoin({
            timeline: this.tripApiService.getTimeline(this.trip.id as string, {
              offset: 0,
              limit: LIMIT
            }),
            trip: this.tripApiService.getById(this.trip.id as string)
          })
        )
      )
      .subscribe((timelineData) => {
        this.elements = timelineData?.timeline || [];
        this.trip = timelineData?.trip;
        this.enrichTimeline();
      });
  }

  elementEdit(type: TripEntityType, element: Element) {
    if (type === TripEntityType.Journal) {
      this.editJournal(element.journal as TripJournalEntry);
    } else if (type === TripEntityType.Campsite) {
      this.editCampsite(element.campsite as TripCampsite);
    }
  }

  editCampsite(item: TripCampsite) {
    this.dialogService
      .open(CampsiteAddDialogComponent, {
        data: {
          campsite: item,
          mode: Mode.Edit,
          trip: this.trip
        }
      })
      .afterClosed()
      .pipe(take(1), filter(Boolean))
      .subscribe((updatedCampsite) => {
        const itemIndex = this.elements.findIndex(
          ({ campsite }) => campsite?.id === (updatedCampsite as TripCampsite).id
        );
        const elements = [...this.elements];
        elements[itemIndex] = { ...elements[itemIndex], campsite: updatedCampsite as TripCampsite };
        this.elements = elements;
        this.changeDetectorRef.detectChanges();
      });
  }

  editJournal(item: TripJournalEntry) {
    this.dialogService
      .open(WritingJournalAddDialogComponent, {
        data: {
          journalEntry: item,
          mode: Mode.Edit,
          friends$: this.friends$
        }
      })
      .afterClosed()
      .pipe(take(1), filter(Boolean))
      .subscribe((journalEntry) => {
        const itemIndex = this.elements.findIndex(
          ({ journal }) => journal?.id === (journalEntry as TripJournalEntry).id
        );
        const elements = [...this.elements];
        elements[itemIndex] = { ...elements[itemIndex], journal: journalEntry as TripJournalEntry };
        this.elements = elements;
        this.changeDetectorRef.detectChanges();
      });
  }

  onEditPhoto(photo: UploadedImage) {
    this.dialogService
      .open(MyAccountTripPhotoEditComponent, {
        data: {
          trip: this.trip,
          photo
        }
      })
      .afterClosed()
      .pipe(
        filter(Boolean),
        switchMap(() =>
          forkJoin({
            timeline: this.tripApiService.getTimeline(this.trip.id as string, {
              offset: 0,
              limit: LIMIT
            }),
            trip: this.tripApiService.getById(this.trip.id as string)
          })
        )
      )
      .subscribe((timelineData) => {
        this.elements = timelineData?.timeline || [];
        this.trip = timelineData?.trip;
        this.enrichTimeline();
      });
  }
}
