<h3>{{ 'COMMON.LEGAL.COOKIE_POLICY.TITLE' | translate }}</h3>
<div class="desktop">
  <div><p [innerHTML]="'COMMON.LEGAL.COOKIE_POLICY.MAIN_TEXT' | translate"></p></div>
  <div><p [innerHTML]="'COMMON.LEGAL.COOKIE_POLICY.HIDDEN_TEXT' | translate"></p></div>
</div>
<div class="mobile">
  <div class="more-text">
    <p [innerHTML]="'COMMON.LEGAL.COOKIE_POLICY.MAIN_TEXT' | translate"></p>
    <details>
      <summary></summary>
      <p [innerHTML]="'COMMON.LEGAL.COOKIE_POLICY.HIDDEN_TEXT' | translate"></p>
    </details>
  </div>
</div>
<ct-icon class="close-icon" size="fit" icon="close" (click)="onCloseNotice()"></ct-icon>
