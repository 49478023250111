import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogService, SpinnerService } from '@ct/components';
import {
  ChannelVideoUploadApiService,
  Destroyable,
  Features,
  TitleConfig,
  TitleFeature,
  VideoChannelEntity
} from '@ct/core';
import { ChannelEditDialogComponent, trackById } from '@ct/shared';
import { throwError } from 'rxjs';
import { catchError, finalize, take, takeUntil } from 'rxjs/operators';

interface QueryParams {
  offset: number;
}

const DEFAULT_OFFSET = 0;

@Component({
  selector: 'ct-user-channels-list',
  templateUrl: './user-channels-list.component.html',
  styleUrls: ['./user-channels-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@Features([TitleFeature()])
export class UserChannelsListComponent extends Destroyable implements OnInit {
  public readonly limit = 20;
  public titleConfig: TitleConfig = {
    titleKey: 'MAIN.FEATURES.MY_ACCOUNT_POSTS'
  };

  public readonly filterForm = new UntypedFormGroup({
    active: new UntypedFormControl('')
  });

  public loading = false;
  public channels: VideoChannelEntity[] = [];
  public showLoadButton = true;

  public queryParams: QueryParams = {
    offset: DEFAULT_OFFSET
  };

  public trackByFn = trackById;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private changeDetectorRef: ChangeDetectorRef,
    private channelVideoUploadApiService: ChannelVideoUploadApiService,
    private spinnerService: SpinnerService,
    private dialogService: DialogService
  ) {
    super();
  }

  ngOnInit() {
    this.route.data.pipe(take(1)).subscribe(({ channels }) => (this.channels = channels));
    this.route.queryParams.pipe(take(1)).subscribe(({ offset }) => {
      this.queryParams = {
        ...this.queryParams,
        offset: +offset || this.queryParams.offset
      };

      this.showLoadButton = !(this.channels.length === 0 || this.channels.length < this.limit);

      this.refreshUrlQueryParams(this.queryParams);
    });

    this.filterForm.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(() => {
      this.loadData({ offset: 0 });
    });
  }

  loadData(params: QueryParams, shouldRefreshUrl = true) {
    this.queryParams = {
      ...this.queryParams,
      ...params
    };
    this.spinnerService.show({ instant: true });
    this.changeDetectorRef.markForCheck();
    return this.loadDataFromApi(this.queryParams)
      .pipe(
        finalize(() => {
          this.spinnerService.hide();
          this.changeDetectorRef.markForCheck();
        })
      )
      .subscribe((entities) => this.refreshList(entities, shouldRefreshUrl));
  }

  onScroll() {
    if (this.loading || !this.showLoadButton) {
      return false;
    }
    this.loading = true;
    this.queryParams.offset = this.queryParams.offset + this.limit;
    this.loadData(this.queryParams, false);
  }

  loadDataFromApi({ offset }: QueryParams) {
    this.loading = true;
    return this.channelVideoUploadApiService.getAll({ range: { limit: this.limit, offset } }).pipe(
      catchError((err) => {
        this.loading = false;
        return throwError(err);
      })
    );
  }

  refreshList(entities: VideoChannelEntity[], shouldRefreshUrl: boolean) {
    if (this.queryParams.offset === 0) {
      this.channels = [...entities];
    } else {
      this.channels.push(...entities);
    }
    this.showLoadButton = !(entities.length === 0 || entities.length < this.limit);
    this.loading = false;
    this.changeDetectorRef.detectChanges();
    if (shouldRefreshUrl) {
      this.refreshUrlQueryParams(this.queryParams);
    }
  }

  refreshUrlQueryParams({ offset }: QueryParams) {
    this.router
      .navigate([], {
        queryParams: { offset },
        queryParamsHandling: 'merge',
        relativeTo: this.route
      })
      .then(() => this.changeDetectorRef.detectChanges());
  }

  onChannelClick(channel: VideoChannelEntity) {
    this.router.navigate(['channels', channel.handle]);
  }

  editChannel(channel: VideoChannelEntity) {
    this.dialogService.open(ChannelEditDialogComponent, {
      data: { channel }
    });
  }
}
