import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FriendsQuery } from '@ct/app-content/my-account/services';
import { AuthQuery } from '@ct/auth';
import { DialogService } from '@ct/components';
import {
  ChannelsQuery,
  EnrichedExploreTimeline,
  FriendStatus,
  GroupTimelineType,
  RelationUserProfile,
  UserProfile
} from '@ct/core';
import { EntityType, getCroppedThumbPublicUrl, LikeApiService, Mode, trackById, TripJournalEntry } from '@ct/shared';
import { WritingJournalAddDialogComponent } from '@ct/shared/modules/add-to-writings-dialog';
import { NotificationQuery } from '@ct/shared/services/notification-state';
import { Observable, Subject } from 'rxjs';
import { filter, map, take, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'ct-my-account-explore-entity',
  templateUrl: './my-account-explore-entity.component.html',
  styleUrls: ['./my-account-explore-entity.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MyAccountExploreEntityComponent implements OnDestroy {
  public timeline: EnrichedExploreTimeline;
  public readonly timelineType = GroupTimelineType;
  public readonly getCroppedThumbPublicUrl = getCroppedThumbPublicUrl;

  public loggedInUser$ = this.authQuery.profile$ as Observable<UserProfile>;
  public loggedInUserId$ = this.loggedInUser$.pipe(map((user) => user?.userId));
  public hasChannels$: Observable<boolean> = this.channelsQuery.hasChannels$;
  public readonly notifications$ = this.notificationQuery.selectNotifications$;
  public loading = false;

  protected readonly trackById = trackById;

  private readonly destroyed$ = new Subject<void>();

  private friends$: Observable<RelationUserProfile[]> = this.friendsQuery.selectAll({
    filterBy: ({ friendStatus }) => friendStatus === FriendStatus.Accepted
  });

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private authQuery: AuthQuery,
    private notificationQuery: NotificationQuery,
    private likeApiService: LikeApiService,
    private dialogService: DialogService,
    private friendsQuery: FriendsQuery,
    private channelsQuery: ChannelsQuery,
    private route: ActivatedRoute
  ) {
    this.route.data
      .pipe(
        map(({ entity }) => entity),
        takeUntil(this.destroyed$)
      )
      .subscribe((timeline) => {
        this.timeline = timeline;
        this.changeDetectorRef.markForCheck();
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  getEntityType(entity: EnrichedExploreTimeline): EntityType {
    if (entity.groupId && entity.id === entity.metadataId) {
      return EntityType.Memory;
    }

    const type = entity.type;

    switch (type) {
      case GroupTimelineType.Memory:
        return EntityType.Memory;
      default:
        return type as unknown as EntityType;
    }
  }

  toJournalEntry(entity: TripJournalEntry | undefined): TripJournalEntry {
    return entity as TripJournalEntry;
  }

  getTripLink(timeline: EnrichedExploreTimeline): string[] {
    return ['/my-account/trips', timeline.entityId as string, timeline.trip?.slug as string, 'timeline'];
  }

  getStoryLink(timeline: EnrichedExploreTimeline): string {
    const slug = timeline.story?.slug as string;
    const slugArray = slug.split('-');
    const id = slugArray[slugArray.length - 1];
    return [
      '/stories',
      id === String(timeline.entityId) ? (timeline.story?.slug as string) : `${slug}-${timeline.entityId}`
    ].join('/');
  }

  editJournal(item: TripJournalEntry) {
    this.dialogService
      .open(WritingJournalAddDialogComponent, {
        data: {
          journalEntry: item,
          mode: Mode.Edit,
          friends$: this.friends$
        }
      })
      .afterClosed()
      .pipe(take(1), filter(Boolean))
      .subscribe((updatedMemory) => {
        this.timeline.journal = updatedMemory as TripJournalEntry;
        this.changeDetectorRef.markForCheck();
      });
  }

  onLike(entity: EnrichedExploreTimeline) {
    this.likeApiService
      .like({
        entityId: entity.metadataId as string,
        entityType: EntityType.Memory
      })
      .subscribe((likes) => {
        entity.metadata = {
          ...(entity.metadata ?? {}),
          likes
        };
        this.changeDetectorRef.markForCheck();
      });
  }

  onDislike(entity: EnrichedExploreTimeline) {
    this.likeApiService
      .dislike({
        entityId: entity.metadataId as string,
        userId: this.authQuery.profile?.userId as string
      })
      .subscribe((likes) => {
        entity.metadata = {
          ...(entity.metadata ?? {}),
          likes
        };
        this.changeDetectorRef.markForCheck();
      });
  }
}
