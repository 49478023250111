import { Injectable } from '@angular/core';
import { VideoChannelEntity } from '@ct/core';
import { Store, StoreConfig } from '@datorama/akita';

export interface ChannelsState {
  channels: VideoChannelEntity[];
}

export function createInitialState(): ChannelsState {
  return {
    channels: []
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'channels' })
export class ChannelsStore extends Store<ChannelsState> {
  constructor() {
    super(createInitialState());
  }
}
