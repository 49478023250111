import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthQuery } from '@ct/auth/services/state/auth.query';
import {
  DeletionDialogComponent,
  DialogService,
  ImageUploadQueueService,
  ImageUploadService,
  MediaSelectDialogComponent,
  SelectOption,
  SpinnerService
} from '@ct/components';
import { DialogButton } from '@ct/components/dialog';
import {
  ChannelVideoUploadApiService,
  DestroyableFeature,
  Features,
  FormStateDispatcher,
  Group,
  GroupFilterType,
  LocalStorageService,
  RelationUserProfile,
  Tag,
  TranscodingState,
  UploadedImage,
  UserProfile,
  VideoOrientation,
  VideoUploadEntity
} from '@ct/core';
import { VideoPlayerDialogComponent } from '@ct/shared';
import { TranslateService } from '@ngx-translate/core';
import { QuillModules } from 'ngx-quill';
import { BehaviorSubject, forkJoin, Observable, of } from 'rxjs';
import { catchError, filter, finalize, first, map, shareReplay, switchMap, take, takeUntil } from 'rxjs/operators';

import { BaseAutosaveFormComponent } from '../../../../classes';
import { Mode } from '../../../../enums';
import { ImageUploadApiService } from '../../../../services/image-upload-api.service';
import { MyAccountPhotoApiService } from '../../../../services/my-account-photos-api.service';
import { TagApiService } from '../../../../services/tag-api.service';
import { GroupApiService } from '../../../group-shared';
import {
  CampsiteQuery,
  JournalEntryApiService,
  TripApiService,
  TripCampsiteApiService,
  TripJournalEntry,
  TripQuery
} from '../../../trip-shared';

const QUILL_MODULES_CONFIG: QuillModules = {
  toolbar: [[{ header: [1, 2, 3, 4, 5, 6, false] }], ['bold', 'italic', { list: 'bullet' }, 'link', 'image']]
};

const TRIP_JOURNAL_ENTRY_AUTOSAVE_CONFIG = {
  autosaveKey: 'TRIP_JOURNAL_ENTRY_CREATE_AUTOSAVE',
  excludeFormFields: [],
  dialogConfig: {
    titleKey: 'MY_ACCOUNT.MY_TRIPS_FEATURE.AUTOSAVE_DIALOG.TITLE',
    messageKey: 'MY_ACCOUNT.MY_TRIPS_FEATURE.AUTOSAVE_DIALOG.MESSAGE',
    confirmKey: 'MY_ACCOUNT.MY_TRIPS_FEATURE.AUTOSAVE_DIALOG.CONFIRM',
    cancelKey: 'MY_ACCOUNT.MY_TRIPS_FEATURE.AUTOSAVE_DIALOG.CANCEL'
  }
};

@Component({
  selector: 'ct-writing-journal-add-dialog',
  templateUrl: './writing-journal-add-dialog.component.html',
  styleUrls: ['./writing-journal-add-dialog.component.scss'],
  providers: [FormStateDispatcher],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@Features([DestroyableFeature()])
export class WritingJournalAddDialogComponent extends BaseAutosaveFormComponent implements OnInit, OnDestroy {
  public readonly destroyed$: Observable<void>;
  public readonly modules: QuillModules = QUILL_MODULES_CONFIG;

  public readonly form = new UntypedFormGroup({
    tags: new UntypedFormControl([]),
    body: new UntypedFormControl('', [Validators.required]),
    tripId: new UntypedFormControl(null),
    campsiteId: new UntypedFormControl(null),
    date: new UntypedFormControl(null),
    photos: new UntypedFormControl([]),
    videos: new UntypedFormControl([]),
    groups: new UntypedFormControl([]),
    users: new UntypedFormControl([]),
    isPublic: new FormControl(false)
  });

  public isLoading = false;
  public trips$: Observable<SelectOption[]> = this.tripQuery
    .selectAll()
    .pipe(map((trips) => trips.map((trip) => ({ value: trip.id, label: trip.title } as SelectOption))));
  public campsites$: Observable<SelectOption[]> = this.campsiteQuery
    .selectAll()
    .pipe(
      map((campsites) => campsites.map((campsite) => ({ value: campsite.id, label: campsite.title } as SelectOption)))
    );
  public groups$: Observable<Group[]> = this.groupApiService
    .findAllMemberGroups({ filterType: GroupFilterType.MemberOf })
    .pipe(shareReplay(1));

  public buttons: DialogButton[] = [
    {
      labelKey: 'COMMON.CANCEL',
      color: 'primary',
      clicked: () => this.onCancel()
    },
    {
      labelKey: 'COMMON.SAVE',
      clicked: () => {
        this.onSave();
      }
    }
  ];

  get waypoints$(): Observable<SelectOption[]> {
    const tripId = this.form.value.tripId;
    return tripId
      ? this.campsiteQuery
          .selectByTripId(tripId)
          .pipe(
            map((campsites) =>
              campsites.map((campsite) => ({ value: campsite.id, label: campsite.title } as SelectOption))
            )
          )
      : this.campsites$;
  }

  get isEditMode() {
    return this.data?.mode === Mode.Edit;
  }

  get photos() {
    return this.form.controls.photos?.value;
  }

  get journalEntry(): TripJournalEntry {
    return this.data?.journalEntry;
  }

  get friends$(): Observable<RelationUserProfile[]> {
    return this.data?.friends$;
  }

  get journalEntryDate(): Date {
    return this.journalEntry?.date;
  }

  protected readonly mediaSelectError$ = new BehaviorSubject<boolean>(false);
  protected readonly uploadingMedia$ = new BehaviorSubject<boolean>(false);
  private interval: number;

  public readonly optionsFn = (match: string) => this.tagApiService.getAll(match);

  public readonly createFn = (name: string) => this.tagApiService.create(name);

  public readonly checkFn = (name: string) => this.tagApiService.getByName(name);

  public readonly labelFn = ({ name }: Tag) => name as string;

  public readonly optionsGroupFn = (match: string) =>
    this.groups$.pipe(
      map((groups) =>
        groups
          .map((group) => ({ ...group, name: group.title }))
          .filter(({ title }) => title.toLowerCase().includes(match?.toLowerCase()))
      )
    );

  public readonly labelGroupFn = ({ title }: Group) => title;

  public readonly optionsFriendFn = (match: string) =>
    this.friends$?.pipe(
      map((friends) =>
        friends
          .map((friend) => ({ ...friend, name: `${friend.firstName} ${friend.lastName} (${friend.username})` }))
          .filter(({ name }) => name.toLowerCase().includes(match?.toLowerCase()))
      )
    ) ?? of([]);

  public readonly labelFriendFn = ({ firstName, lastName, username }: RelationUserProfile) =>
    `${firstName} ${lastName} (${username})`;

  constructor(
    @Inject(MAT_DIALOG_DATA) public readonly data: any,
    protected localStorageService: LocalStorageService,
    protected translateService: TranslateService,
    protected dialogService: DialogService,
    private dialogRef: MatDialogRef<WritingJournalAddDialogComponent>,
    private formState: FormStateDispatcher,
    private spinnerService: SpinnerService,
    private imageUploadApiService: ImageUploadApiService,
    private journalEntryApiService: JournalEntryApiService,
    private imageUploadService: ImageUploadService,
    private myAccountPhotoApiService: MyAccountPhotoApiService,
    private tagApiService: TagApiService,
    private groupApiService: GroupApiService,
    private tripCampsiteApiService: TripCampsiteApiService,
    private tripApiService: TripApiService,
    private imageUploadQueueService: ImageUploadQueueService,
    private videoUploadApiService: ChannelVideoUploadApiService,
    private campsiteQuery: CampsiteQuery,
    private tripQuery: TripQuery,
    private authQuery: AuthQuery,
    private changeDetectorRef: ChangeDetectorRef,
    private readonly snackBar: MatSnackBar
  ) {
    super(localStorageService, dialogService, translateService, TRIP_JOURNAL_ENTRY_AUTOSAVE_CONFIG);
    if (this.campsiteQuery.getCount() === 0) {
      this.tripCampsiteApiService.getAll().subscribe();
    }

    const tripsCount = this.tripQuery.getCount();
    if (tripsCount === 0 || tripsCount === 1) {
      const profile = this.authQuery.profile as UserProfile;
      this.tripApiService.getAllByAuthorId(profile.userId, {}).subscribe();
    }
  }

  ngOnInit() {
    this.startVideosStatusListener();
    if (this.isEditMode && this.journalEntry) {
      this.loadAdditionalData().subscribe((entry) => {
        this.form.patchValue({ ...entry, isPublic: !entry.isPrivate });
        this.form.get('tripId')?.disable();
        this.listenForTripChange();
      });
    } else {
      this.setForm(this.form);
      this.getAutosavedItem()
        .pipe(take(1))
        .subscribe((autosavedPost) => {
          if (!autosavedPost) {
            this.stopAutosave();
            this.startAutosave();
            return;
          }
          this.form.patchValue(autosavedPost);
          this.listenForTripChange();
          this.startAutosave();
        });
    }
  }

  ngOnDestroy() {
    this.stopVideosStatusListener();
  }

  onSave() {
    this.formState.onSubmit.notify();

    if (this.form.invalid) {
      return;
    }

    this.isLoading = true;
    this.spinnerService.show();
    this.changeDetectorRef.markForCheck();

    const { isPublic, ...entry } = this.form.getRawValue();

    return (
      !this.isEditMode
        ? this.journalEntryApiService.create({ ...entry, isPrivate: !isPublic })
        : this.journalEntryApiService.update(this.journalEntry.id as string, { ...entry, isPrivate: !isPublic })
    )
      .pipe(
        finalize(() => {
          this.isLoading = false;
          this.spinnerService.hide();
          this.changeDetectorRef.markForCheck();
        })
      )
      .subscribe((journalEntry) => {
        this.stopAutosave();
        this.dialogRef.close(journalEntry);
      });
  }

  loadAdditionalData() {
    return forkJoin([
      this.journalEntry.photoIds?.length ? this.imageUploadApiService.getByIds(this.journalEntry.photoIds) : of([]),
      this.journalEntry.groupIds?.length
        ? this.groups$.pipe(
            map((groups) => groups.filter(({ id }) => this.journalEntry.groupIds?.includes(String(id))))
          )
        : of([]),
      this.journalEntry.userIds?.length
        ? this.friends$?.pipe(
            first(),
            map((friends) => friends.filter(({ userId }) => this.journalEntry.userIds?.includes(String(userId))))
          ) ?? of([])
        : of([]),
      this.journalEntry.videoIds?.length
        ? this.videoUploadApiService.getById(this.journalEntry.videoIds[0]).pipe(map((video) => [video]))
        : of([])
    ]).pipe(
      map(([photos, groups, users, videos]) => ({
        ...this.journalEntry,
        photos: photos.map((photo) => ({ ...photo, id: String(photo?.id) })),
        videos: videos.map((video) => ({ ...video, id: String(video?.id) })),
        groups: groups.map((group) => ({ ...group, id: String(group?.id), name: group.title })),
        users: users.map((user) => ({
          ...user,
          id: String(user?.id),
          name: `${user.firstName} ${user.lastName} (${user.username})`
        }))
      }))
    );
  }

  onAddMedia(files: File[]): void {
    this.mediaSelectError$.next(false);
    const isVideoUpload = files.some((file) => file.type.includes('video'));
    if (files.length > 1 && isVideoUpload) {
      this.mediaSelectError$.next(true);
      return;
    }

    this.uploadingMedia$.next(true);

    if (isVideoUpload) {
      this.onUploadVideo(files[0]);
    } else {
      this.imageUploadQueueService.onStartUpload(files, {
        uploadFn: (file: File) =>
          this.imageUploadApiService
            .getUploadLink(file)
            .pipe(
              switchMap((res) =>
                this.imageUploadApiService
                  .useUploadLink(file, res.uploadLink)
                  .pipe(switchMap(() => this.imageUploadApiService.getById(res.id)))
              )
            ),
        noProgressTracking: true
      });
      // TODO: verofy if this long check inside `first` pipe is needed
      this.imageUploadQueueService.loading$
        .pipe(first((value) => !value && this.imageUploadQueueService.uploadedImages.every(Boolean)))
        .subscribe(() => {
          const photos = this.imageUploadQueueService.uploadedImages.map((image) => image.data).filter(Boolean);
          this.form.controls.photos.patchValue([...this.form.controls.photos.value, ...photos]);
          this.uploadingMedia$.next(false);
        });
    }
  }

  onAddPhotos() {
    this.imageUploadService
      .showUploadDialog({
        titleKey: 'MY_ACCOUNT.ADD_PHOTOS',
        multiple: true,
        selectable: true,
        firstTabKey: 'MY_ACCOUNT.UPLOAD_PHOTOS',
        secondTabKey: 'MY_ACCOUNT.MY_PHOTOS',
        getAllImagesFn: () => this.myAccountPhotoApiService.getAllMyPhotos(),
        uploadFn: (file: File) =>
          this.imageUploadApiService
            .getUploadLink(file)
            .pipe(
              switchMap((res) =>
                this.imageUploadApiService
                  .useUploadLink(file, res.uploadLink)
                  .pipe(switchMap(() => this.imageUploadApiService.getById(res.id)))
              )
            ),
        noProgressTracking: true
      })
      .afterClosed()
      .pipe(take(1))
      .subscribe((photos) => {
        this.form.controls.photos.patchValue([...this.form.controls.photos.value, ...photos]);
        this.changeDetectorRef.detectChanges();
      });
  }

  onRemovePhoto({ id }: UploadedImage) {
    const photos = this.photos.filter((photo: UploadedImage) => photo.id !== id);
    this.form.controls.photos.patchValue(photos);
    this.changeDetectorRef.detectChanges();
  }

  onCancel() {
    this.stopAutosave();
    this.dialogRef.close();
  }

  async onUploadVideo(file: File) {
    let orientation = VideoOrientation.Landscape;
    let width = 0;
    let height = 0;
    const duration = await this.onGetDuration(file);
    try {
      const result = (await this.onGetOrientation(file)) ?? { width, height, orientation };
      orientation = result.orientation;
      width = result.width;
      height = result.height;
    } catch (e) {
      return this.snackBar.open(`Error parsing video "${file.name}". Please, select another video.`, 'close', {
        duration: 10000,
        horizontalPosition: 'right',
        verticalPosition: 'bottom'
      });
    }
    this.spinnerService.show({ instant: true });
    this.videoUploadApiService
      .getUploadLink({ orientation, duration, width, height, fileName: file.name })
      .pipe(
        switchMap(({ uploadLink, ...video }) => {
          return this.videoUploadApiService.useUploadLink(file, uploadLink).pipe(map(() => video));
        }),
        finalize(() => {
          this.spinnerService.hide();
          this.uploadingMedia$.next(false);
        }),
        catchError((err) => {
          if (err.error.error && err.error.message) {
            this.snackBar.open(`${err.error.error}: ${err.error.message}`, 'close', {
              duration: 10000,
              horizontalPosition: 'right',
              verticalPosition: 'bottom'
            });
          }
          return of(null);
        })
      )
      .subscribe((video: VideoUploadEntity | null) => {
        if (video) {
          this.form.controls.videos.setValue([video]);
        }
      });
  }

  onDeleteMemory(): void {
    this.dialogService
      .open(DeletionDialogComponent, {
        data: {
          title: this.translateService.instant('MY_ACCOUNT.MY_TRIPS_FEATURE.DELETE_JOURNAL_ENTRY_TITLE'),
          message: this.translateService.instant('MY_ACCOUNT.MY_TRIPS_FEATURE.DELETE_JOURNAL_ENTRY_MESSAGE')
        }
      })
      .afterClosed()
      .pipe(
        take(1),
        filter(Boolean),
        switchMap(() => this.journalEntryApiService.remove(this.journalEntry.id as string))
      )
      .subscribe(() => {
        this.dialogRef.close(true);
      });
  }

  onPlayVideo(): void {
    this.dialogService.open(VideoPlayerDialogComponent, { data: this.form.controls.videos.value[0] });
  }

  onRemoveVideo(): void {
    this.form.get('videos')?.patchValue([]);
  }

  onSelectExisting(): void {
    this.dialogService
      .open(MediaSelectDialogComponent, {
        data: {
          config: {
            titleKey: 'MY_ACCOUNT.SELECT_FILES',
            multiple: true,
            selectable: true,
            firstTabKey: 'MY_ACCOUNT.MY_VIDEOS',
            secondTabKey: 'MY_ACCOUNT.MY_PHOTOS',
            getAllImagesFn: () => this.myAccountPhotoApiService.getAllMyPhotos(),
            getAllVideosFn: () => this.videoUploadApiService.getAllVideos()
          }
        }
      })
      .afterClosed()
      .pipe(filter(Boolean))
      .subscribe((result) => {
        const { video, images } = result as unknown as { video: VideoUploadEntity; images: UploadedImage[] };
        if (video) {
          this.form.controls.videos.setValue([video]);
          this.form.controls.photos.setValue([]);
        } else if (images) {
          this.form.controls.videos.setValue([]);
          this.form.controls.photos.setValue(images);
        }
        this.changeDetectorRef.markForCheck();
      });
  }

  async onGetOrientation(videoFile: Blob) {
    // eslint-disable-next-line no-prototype-builtins
    if (HTMLVideoElement.prototype.hasOwnProperty('requestVideoFrameCallback')) {
      const video = await this.getVideoElement(videoFile);
      video.muted = true;
      video.playbackRate = 16;
      const { videoWidth, videoHeight } = video;
      video.pause();
      window.URL.revokeObjectURL(video.src);
      video.remove();
      const orientation = videoWidth < videoHeight ? VideoOrientation.Portrait : VideoOrientation.Landscape;
      return { width: videoWidth, height: videoHeight, orientation };
    } else {
      console.error("your browser doesn't support this API yet");
    }
  }

  async onGetDuration(videoFile: Blob) {
    return new Promise<number>((resolve) => {
      // eslint-disable-next-line no-prototype-builtins
      const video = document.createElement('video');
      video.preload = 'metadata';

      video.onloadedmetadata = () => {
        window.URL.revokeObjectURL(video.src);
        const duration = video.duration;
        video.remove();
        resolve(duration);
      };

      video.src = URL.createObjectURL(videoFile);
    });
  }

  private async getVideoElement(videoFile: Blob) {
    const video = document.createElement('video');
    video.crossOrigin = 'anonymous';
    video.style.display = 'none';
    video.src = URL.createObjectURL(videoFile);
    document.body.append(video);
    await video.play();
    return video;
  }

  private listenForTripChange(): void {
    this.form
      .get('tripId')
      ?.valueChanges.pipe(takeUntil(this.destroyed$))
      .subscribe(() => this.form.get('campsiteId')?.reset(null));
  }

  private startVideosStatusListener() {
    this.interval = setInterval(() => {
      const processingVideos = (this.form.controls.videos?.value as VideoUploadEntity[])?.filter(
        ({ transcodingJobStatus }) => transcodingJobStatus !== TranscodingState.SUCCEEDED
      );

      if (processingVideos?.length > 0) {
        this.videoUploadApiService
          .getVideosStatus(processingVideos.map(({ id }) => id as string))
          .subscribe((videos) => {
            const videosMap: Map<string, TranscodingState> = new Map(
              videos.map((video) => [video.id as string, video.transcodingJobStatus])
            );
            this.form.controls.videos.setValue(
              (this.form.controls.videos?.value as VideoUploadEntity[])?.map((video) => {
                if (videosMap.has(video.id as string)) {
                  return {
                    ...video,
                    transcodingJobStatus: videosMap.get(video.id as string) as TranscodingState
                  };
                }

                return video;
              })
            );

            this.changeDetectorRef.markForCheck();
          });
      }
    }, 30000) as unknown as number;
  }

  private stopVideosStatusListener() {
    clearInterval(this.interval);
  }
}
