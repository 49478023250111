<div
  class="px-4 px-md-5 py-4 writings-list"
  *ngIf="journalEntries?.length || campsites?.length; else noEntries"
  infinite-scroll
  [infiniteScrollDistance]="2"
  [infiniteScrollThrottle]="50"
  (scrolled)="onScroll()"
>
  <section class="campsites" *ngIf="campsites?.length">
    <a
      *ngIf="campsites?.length"
      [class.filter-active]="queryParams.campsiteId === campsiteAllFilter.id"
      class="filter pr-3"
      (click)="onFilterChanged(campsiteAllFilter)"
    >
      {{ campsiteAllFilter.title | translate }}
    </a>
    <a
      *ngFor="let campsite of campsites"
      [class.filter-active]="queryParams.campsiteId === campsite.id"
      class="filter pr-3"
      (click)="onFilterChanged(campsite)"
    >
      {{ campsite.title }}
    </a>
  </section>

  <ng-container *ngIf="!journalEntries?.length">
    <ng-container *ngTemplateOutlet="noEntries"></ng-container>
  </ng-container>

  <ct-journal-entry
    *ngFor="let entry of journalEntries; trackBy: trackByFn"
    class="mb-4"
    [journalEntry]="entry"
    [canEdit]="isAuthor"
    [canShare]="isAuthor && (hasChannels$ | async)"
    (edit)="editJournal(entry)"
  ></ct-journal-entry>
  <div
    *ngIf="showLoadButton"
    class="load-more"
    [ngClass]="{ disabled: loading }"
    (click)="onScroll()"
    disabled="loading"
  >
    Load More
  </div>
</div>

<ng-template #noEntries>
  <ct-empty-call-to-action
    *ngIf="isAuthor"
    buttonIcon="add-circle"
    emptyMessageTextKey="MY_ACCOUNT.EMPTY_ENTITY.NO_CONTENT"
    buttonTextKey="MY_ACCOUNT.EMPTY_ENTITY.ADD_NOW"
    (buttonClick)="addDialog()"
  ></ct-empty-call-to-action>
</ng-template>
