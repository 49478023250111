import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';
import { CardWrapperItem, DialogService } from '@ct/components';

import { EntityType } from '../../../../enums';
import { RepostChannelDialogComponent } from '../../../../modules/repost-channel-dialog';
import { Trip } from '../../interfaces';

@Component({
  selector: 'ct-trip-card',
  templateUrl: './trip-card.component.html',
  styleUrls: ['./trip-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TripCardComponent implements OnChanges {
  private dialogService = inject(DialogService);
  @Input() readonly trip: Trip | undefined;
  @Input() readonly link: string[] | string;
  @Input() readonly canEdit: boolean = false;
  @Input() readonly canOpen: boolean = true;
  @Input() readonly isHeading: boolean;

  @Output() readonly edit: EventEmitter<void> = new EventEmitter<void>();
  @Output() readonly share: EventEmitter<void> = new EventEmitter<void>();
  @Output() readonly openTrip: EventEmitter<void> = new EventEmitter<void>();

  cardItem: CardWrapperItem;

  protected readonly entityType = EntityType;

  get placeholderPath(): string {
    let index = 0;
    if (this.trip?.id) {
      index = Number(this.trip.id) % 4;
    }
    return `assets/placeholders/camping_bg_${index}.jpeg`;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.trip && this.trip) {
      let photoIds;
      if (this.trip.featuredPhotoId) {
        photoIds = [this.trip.featuredPhotoId, ...(this.trip.photoIds ?? [])];
      } else {
        photoIds = [...(this.trip.photoIds ?? [])];
      }
      this.cardItem = { ...this.trip, photoIds };
    }
  }

  onEditClick() {
    this.edit.emit();
  }

  onShareClick() {
    this.share.emit();
  }

  onOpenClick() {
    this.openTrip.emit();
  }

  onRepostTrip(event: { entityId: string; entityType: EntityType }): void {
    this.dialogService.open(RepostChannelDialogComponent, {
      data: event
    });
  }
}
