<section class="post" *ngIf="post">
  <p *ngIf="showBanner">
    <mat-toolbar color="accent">
      <mat-toolbar-row class="review-note">
        <ng-container [ngSwitch]="status">
          <ng-container *ngSwitchCase="blogPostStatus.WaitingApproval">{{
            'MY_ACCOUNT.MY_POSTS_FEATURE.WAITING_APPROVAL_DESCRIPTION' | translate
          }}</ng-container>
          <ng-container *ngSwitchCase="blogPostStatus.Suspended">{{
            'MY_ACCOUNT.MY_POSTS_FEATURE.SUSPENDED_DESCRIPTION' | translate
          }}</ng-container>
          <ng-container *ngSwitchCase="blogPostStatus.FlaggedForReview">{{
            'MY_ACCOUNT.MY_POSTS_FEATURE.IN_REVIEW_DESCRIPTION' | translate
          }}</ng-container>
        </ng-container>
      </mat-toolbar-row>
    </mat-toolbar>
  </p>
  <div class="d-flex flex-wrap mb-3">
    <div *ngIf="!isAdminView && !canEdit" class="flag" [ngClass]="{ disabled: !currentUser }" (click)="flagPost()">
      <ct-icon class="icon" size="fit" title="flag" icon="flag"></ct-icon>
      <span class="flag__note">{{ 'COMMON.FLAG_INAPPROPRIATE' | translate }}</span>
    </div>
    <div class="ml-md-auto d-flex justify-content-end">
      <div *ngIf="isAdminView || (canEdit && editLink)" class="d-flex justify-content-end align-items-center">
        <ct-blog-post-status [status]="status"></ct-blog-post-status>
        <ct-link [link]="editLink">
          <ct-icon icon="edit" title="edit" class="mr-2 ml-2 edit-icon"></ct-icon>
        </ct-link>
        <ct-repost-entity
          *ngIf="hasChannels$ | async"
          class="mr-2 ml-2 edit-icon"
          [entityId]="post.id"
          [entityType]="entityType.Story"
          (repost)="onRepostStory($event)"
        ></ct-repost-entity>
      </div>
      <ng-container *ngIf="isAdminView">
        <ng-container *ngTemplateOutlet="adminActions"></ng-container>
      </ng-container>
      <ct-like
        [likes]="likes"
        [user]="canEdit ? null : currentUser"
        (like)="onLike($event)"
        (dislike)="onDislike($event)"
        [class.mr-4]="!isAdminView"
      ></ct-like>
      <ct-social-sharing
        *ngIf="!isAdminView"
        [isActive]="post?.status === blogPostStatus.Published"
        [text]="post?.title"
        [url]="blogUrl"
      ></ct-social-sharing>
    </div>
  </div>
  <div *ngIf="!currentUser" class="signin-warning-wrapper">
    <span class="signin-warning"
      ><ct-icon class="signin-warning-icon" icon="warn-circle"></ct-icon>Please sign in to like or comment on this
      story.</span
    >
  </div>
  <h1 class="title">{{ post?.title }}</h1>
  <div *ngIf="showSeries" class="series-wrapper">
    <ct-blog-post-series [series]="series" [showSeries]="true"></ct-blog-post-series>
  </div>
  <h2 class="disclaimer mr-3">{{ 'BLOG.DISCLOSURE' | translate }}</h2>
  <figure *ngIf="post?.featuredPhoto" class="featured-image mb-4">
    <img [src]="featuredPublicUrl" />
    <figcaption class="mt-2">{{ post?.featuredPhotoTitle }}</figcaption>
  </figure>
  <div #videoContainer class="youtube-video">
    <ct-youtube-player
      *ngIf="post.featuredYoutubeVideo"
      [videoLink]="post.featuredYoutubeVideo | youtubeId"
      [height]="500"
      [width]="videoContainer.offsetWidth"
    ></ct-youtube-player>
  </div>
  <div class="mb-3">
    <ct-text-editor-view [content]="post?.body"></ct-text-editor-view>
  </div>
  <ng-container *ngIf="isAdminView">
    <ng-container *ngTemplateOutlet="adminActions"></ng-container>
  </ng-container>
  <div class="mb-3">
    <ng-container *ngTemplateOutlet="isGallery ? gallery : grid"></ng-container>
  </div>
  <!-- TODO: add video gallery post?.videos-->
  <div *ngIf="post?.youtubeVideos?.length">
    <h2 class="item-title">{{ 'MY_ACCOUNT.MY_POSTS_FEATURE.YOUTUBE_VIDEOS' | translate }}</h2>
    <div #videoContainer class="youtube-video">
      <div class="video-player">
        <ct-youtube-player
          *ngIf="selectedYoutubeVideo"
          [videoLink]="selectedYoutubeVideo | youtubeId"
          [height]="500"
          [width]="videoContainer.offsetWidth - 200"
        ></ct-youtube-player>
      </div>
      <div>
        <ct-youtube-video-grid
          class="video-grid"
          style=""
          [col]="12"
          [videos]="post.youtubeVideos | youtubeObjectArray"
          [limit]="10"
          (played)="selectVideo($event)"
        ></ct-youtube-video-grid>
      </div>
    </div>
  </div>
  <div *ngIf="post?.tags?.length" class="tags mt-5">
    <ct-icon icon="tag" class="mb-1"></ct-icon>
    <p class="mb-1 mr-2">TAGS</p>

    <div>
      <ct-link
        *ngFor="let tag of post?.tags"
        [link]="['/stories/by-tag']"
        [queryParams]="{ tagId: tag.id, authorId: null }"
        inline="true"
      >
        <ct-tag class="mr-2 mb-1" [name]="tag?.name"></ct-tag>
      </ct-link>
    </div>
  </div>
  <ct-active-campaign-sign-up class="mt-5"></ct-active-campaign-sign-up>
  <div class="line"></div>
  <div class="mb-3">
    <ct-entity-comments
      [entityId]="post?.id"
      [disableComments]="post?.disableComments"
      [user]="currentUser"
      [hideCount]="false"
      [isAdminView]="isAdminView"
    ></ct-entity-comments>
  </div>
</section>

<ng-template #gallery>
  <ct-gallery
    *ngIf="gallery && images?.length"
    [items]="images"
    [thumbPosition]="thumbnailsPosition"
    [imageDescriptionPrefix]="post?.title"
    photos
  ></ct-gallery>
</ng-template>
<ng-template #grid>
  <h2 class="item-title">{{ 'MY_ACCOUNT.MY_POSTS_FEATURE.PHOTOS' | translate }}</h2>
  <ct-photo-grid [photos]="post?.photos"></ct-photo-grid>
</ng-template>

<ng-template #adminActions>
  <div class="d-flex justify-content-end align-items-center">
    <ct-button
      *ngIf="post?.status !== blogPostStatus.Published"
      color="primary"
      variant="flat"
      class="ml-2"
      (click)="onPublish()"
    >
      {{ 'MY_ACCOUNT.MY_POSTS_FEATURE.PUBLISH' | translate }}
    </ct-button>
    <ct-button
      *ngIf="post?.status !== blogPostStatus.Draft"
      color="accent"
      variant="flat"
      class="ml-2"
      (click)="onDraft()"
    >
      {{ 'MY_ACCOUNT.MY_POSTS_FEATURE.SWITCH_TO_DRAFT' | translate }}
    </ct-button>
    <ct-button
      *ngIf="post?.status !== blogPostStatus.Suspended"
      color="warn"
      variant="flat"
      class="ml-2"
      (click)="onSuspend()"
    >
      {{ 'MY_ACCOUNT.MY_POSTS_FEATURE.SUSPEND' | translate }}
    </ct-button>
  </div>
</ng-template>
