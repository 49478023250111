import { IconType } from '@ct/components/icon/components/icon/icon.types';
import { SelectOption } from '@ct/components/select/select.interface';

export enum SocialLinkType {
  Airchat = 'airchat',
  Amazon = 'amazon',
  AndroidPlayStore = 'android_play_store',
  AppleAppStore = 'apple_app_store',
  AppleMusic = 'apple_music',
  ApplePodcast = 'apple_podcast',
  Bandcamp = 'bandcamp',
  Bereal = 'bereal',
  Bluesky = 'bluesky',
  Cameo = 'cameo',
  Clubhouse = 'clubhouse',
  Discord = 'discord',
  Email = 'email_address',
  Etsy = 'etsy',
  Facebook = 'facebook',
  GitHub = 'github',
  Instagram = 'instagram',
  Kick = 'kick',
  Linkedin = 'linkedin',
  Mastodon = 'mastodon',
  Patreon = 'patreon',
  Payment = 'payment',
  Phone = 'phone',
  Pinterest = 'pinterest',
  Poshmark = 'poshmark',
  Signal = 'signal',
  Snapchat = 'snapchat',
  SoundCloud = 'soundcloud',
  Spotify = 'spotify',
  Substack = 'substack',
  Telegram = 'telegram',
  TikTok = 'tiktok',
  Threads = 'threads',
  Twitch = 'twitch',
  Website = 'website',
  WhatsApp = 'whatsapp',
  WhatsAppChannel = 'whatsapp_channel',
  YouTube = 'youtube',
  X = 'x',
  RedNote = 'rednote',
  Lemon8 = 'lemon8'
}

export const SOCIAL_ICONS_MAP: Record<SocialLinkType, IconType> = {
  [SocialLinkType.Airchat]: 'a-solid',
  [SocialLinkType.Amazon]: 'amazon-brands-solid',
  [SocialLinkType.AndroidPlayStore]: 'google-play-brands-solid',
  [SocialLinkType.AppleAppStore]: 'app-store-brands-solid',
  [SocialLinkType.AppleMusic]: 'music-solid',
  [SocialLinkType.ApplePodcast]: 'podcast-solid',
  [SocialLinkType.Bandcamp]: 'bandcamp-brands-solid',
  [SocialLinkType.Bereal]: 'b-solid',
  [SocialLinkType.Bluesky]: 'bluesky-brands-solid',
  [SocialLinkType.Cameo]: 'c-solid',
  [SocialLinkType.Clubhouse]: 'c-solid',
  [SocialLinkType.Discord]: 'discord-brands-solid',
  [SocialLinkType.Email]: 'email',
  [SocialLinkType.Etsy]: 'etsy-brands-solid',
  [SocialLinkType.Facebook]: 'facebook-brands-solid',
  [SocialLinkType.GitHub]: 'github-brands-solid',
  [SocialLinkType.Instagram]: 'instagram',
  [SocialLinkType.Kick]: 'k-solid',
  [SocialLinkType.Linkedin]: 'linkedin-in-brands-solid',
  [SocialLinkType.Mastodon]: 'mastodon-brands-solid',
  [SocialLinkType.Patreon]: 'patreon-brands-solid',
  [SocialLinkType.Payment]: 'dollar-sign-solid',
  [SocialLinkType.Phone]: 'mobile-screen-button-solid',
  [SocialLinkType.Pinterest]: 'pinterest-brands-solid',
  [SocialLinkType.Poshmark]: 'p-solid',
  [SocialLinkType.Signal]: 'signal-messenger-brands-solid',
  [SocialLinkType.Snapchat]: 'snapchat-brands-solid',
  [SocialLinkType.SoundCloud]: 'soundcloud-brands-solid',
  [SocialLinkType.Spotify]: 'spotify-brands-solid',
  [SocialLinkType.Substack]: 's-solid',
  [SocialLinkType.Telegram]: 'telegram-brands-solid',
  [SocialLinkType.TikTok]: 'tiktok-brands-solid',
  [SocialLinkType.Threads]: 'threads-brands-solid',
  [SocialLinkType.Twitch]: 'twitch-brands-solid',
  [SocialLinkType.Website]: 'globe-solid',
  [SocialLinkType.WhatsApp]: 'whatsapp-brands-solid',
  [SocialLinkType.WhatsAppChannel]: 'whatsapp-brands-solid',
  [SocialLinkType.YouTube]: 'youtube-brands-solid',
  [SocialLinkType.X]: 'x-twitter-brands-solid',
  [SocialLinkType.RedNote]: 'r-solid',
  [SocialLinkType.Lemon8]: 'l-solid'
};
export const SOCIAL_LABELS_MAP: Record<SocialLinkType, string> = {
  [SocialLinkType.Airchat]: 'Airchat',
  [SocialLinkType.Amazon]: 'Amazon',
  [SocialLinkType.AndroidPlayStore]: 'Android Play Store',
  [SocialLinkType.AppleAppStore]: 'Apple App Store',
  [SocialLinkType.AppleMusic]: 'Apple Music',
  [SocialLinkType.ApplePodcast]: 'Apple Podcast',
  [SocialLinkType.Bandcamp]: 'Bandcamp',
  [SocialLinkType.Bereal]: 'Bereal',
  [SocialLinkType.Bluesky]: 'Bluesky',
  [SocialLinkType.Cameo]: 'Cameo',
  [SocialLinkType.Clubhouse]: 'Clubhouse',
  [SocialLinkType.Discord]: 'Discord',
  [SocialLinkType.Email]: 'Email',
  [SocialLinkType.Etsy]: 'Etsy',
  [SocialLinkType.Facebook]: 'Facebook',
  [SocialLinkType.GitHub]: 'GitHub',
  [SocialLinkType.Instagram]: 'Instagram',
  [SocialLinkType.Kick]: 'Kick',
  [SocialLinkType.Linkedin]: 'Linkedin',
  [SocialLinkType.Mastodon]: 'Mastodon',
  [SocialLinkType.Patreon]: 'Patreon',
  [SocialLinkType.Payment]: 'Payment',
  [SocialLinkType.Phone]: 'Phone',
  [SocialLinkType.Pinterest]: 'Pinterest',
  [SocialLinkType.Poshmark]: 'Poshmark',
  [SocialLinkType.Signal]: 'Signal',
  [SocialLinkType.Snapchat]: 'Snapchat',
  [SocialLinkType.SoundCloud]: 'SoundCloud',
  [SocialLinkType.Spotify]: 'Spotify',
  [SocialLinkType.Substack]: 'Substack',
  [SocialLinkType.Telegram]: 'Telegram',
  [SocialLinkType.TikTok]: 'TikTok',
  [SocialLinkType.Threads]: 'Threads',
  [SocialLinkType.Twitch]: 'Twitch',
  [SocialLinkType.Website]: 'Website',
  [SocialLinkType.WhatsApp]: 'WhatsApp',
  [SocialLinkType.WhatsAppChannel]: 'WhatsApp Channel',
  [SocialLinkType.YouTube]: 'YouTube',
  [SocialLinkType.X]: 'X (ex Twitter)',
  [SocialLinkType.RedNote]: 'RedNote',
  [SocialLinkType.Lemon8]: 'Lemon8'
};

export const SOCIAL_LINKS_OPTIONS: SelectOption<SocialLinkType>[] = Object.values(SocialLinkType).map((type) => ({
  value: type,
  label: SOCIAL_LABELS_MAP[type],
  icon: SOCIAL_ICONS_MAP[type]
}));
