import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { DialogService, IconType } from '@ct/components';
import { DestroyableFeature, Features, FormStateDispatcher, GroupTimelineType } from '@ct/core';
import { WritingJournalAddDialogComponent } from '@ct/shared/modules/add-to-writings-dialog';
import { Observable } from 'rxjs';

import { SelectDocumentDialogComponent } from '../select-document-dialog';
import { SelectStoryDialogComponent } from '../select-story-dialog';
import { SelectTripDialogComponent } from '../select-trip-dialog';
import { SelectVideoDialogComponent } from '../select-video-dialog';

interface AddOption {
  icon: IconType;
  titleKey: string;
  type: GroupTimelineType;
}

@Component({
  selector: 'ct-select-group-entity-dialog',
  templateUrl: './select-group-entity-dialog.component.html',
  styleUrls: ['./select-group-entity-dialog.component.scss'],
  providers: [FormStateDispatcher],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@Features([DestroyableFeature()])
export class SelectGroupEntityDialogComponent {
  public static dialogConfig: MatDialogConfig = {
    width: '30%',
    minWidth: '380px'
  };

  public readonly destroyed$: Observable<void>;
  public readonly addOptions: AddOption[] = [
    {
      icon: 'cabin',
      titleKey: 'Trip',
      type: GroupTimelineType.Trip
    },
    {
      icon: 'book',
      titleKey: 'Memory',
      type: GroupTimelineType.Memory
    },
    {
      icon: 'book',
      titleKey: 'Story',
      type: GroupTimelineType.Story
    }
  ];

  constructor(
    private readonly dialogRef: MatDialogRef<SelectGroupEntityDialogComponent>,
    private readonly dialogService: DialogService,
    @Inject(MAT_DIALOG_DATA) private readonly data: { isChannel: boolean; channelId: string }
  ) {
    if (this.data.isChannel) {
      this.addOptions.push(
        {
          icon: 'video-camera',
          titleKey: 'Video',
          type: GroupTimelineType.Video
        },
        {
          icon: 'list',
          titleKey: 'Document',
          type: GroupTimelineType.Document
        }
      );
    }
  }

  onOptionSelect(option: AddOption) {
    switch (option.type) {
      case GroupTimelineType.Trip:
        return this.onSelectTrip();
      case GroupTimelineType.Story:
        return this.onSelectStory();
      case GroupTimelineType.Memory:
        return this.onSelectJournal();
      case GroupTimelineType.Video:
        return this.onSelectVideo();
      case GroupTimelineType.Document:
        return this.onSelectDocument();
    }
  }

  onSelectTrip() {
    this.dialogService
      .open(SelectTripDialogComponent)
      .afterClosed()
      .subscribe((result) =>
        this.dialogRef.close(result ? { entity: result, entityType: GroupTimelineType.Trip } : null)
      );
  }

  onSelectStory() {
    this.dialogService
      .open(SelectStoryDialogComponent)
      .afterClosed()
      .subscribe((result) =>
        this.dialogRef.close(result ? { entity: result, entityType: GroupTimelineType.Story } : null)
      );
  }

  onSelectJournal() {
    this.dialogService
      .open(WritingJournalAddDialogComponent)
      .afterClosed()
      .subscribe((result) =>
        this.dialogRef.close(result ? { entity: result, entityType: GroupTimelineType.Memory } : null)
      );
  }

  onSelectVideo() {
    this.dialogService
      .open(SelectVideoDialogComponent)
      .afterClosed()
      .subscribe((result) =>
        this.dialogRef.close(result ? { entity: result, entityType: GroupTimelineType.Video } : null)
      );
  }

  onSelectDocument() {
    this.dialogService
      .open(SelectDocumentDialogComponent, {
        data: { channelId: this.data?.channelId }
      })
      .afterClosed()
      .subscribe((result) =>
        this.dialogRef.close(result ? { entity: result, entityType: GroupTimelineType.Document } : null)
      );
  }
}
