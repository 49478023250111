<ct-dialog-content titleKey="Select Video To Share" [closeIcon]="true" headerIcon="add-circle">
  <div class="media-select-wrapper">
    <ct-file-upload-placeholder
      [multiple]="false"
      accept="video/*"
      (selectFiles)="onUploadVideo($event)"
    ></ct-file-upload-placeholder>
    OR
    <ct-button (click)="onSelectExisting()">Select existing</ct-button>
  </div>
  <ct-spinner-simple class="uploading-media-spinner" *ngIf="uploadingMedia$ | async"></ct-spinner-simple>
</ct-dialog-content>
