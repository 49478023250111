<div class="px-4 px-md-5 py-4 group-timeline">
  <ct-channel-add-entry
    *ngIf="canPost"
    [channelId]="group.id"
    (addToTimeline)="onAddTimeline($event)"
  ></ct-channel-add-entry>
  <div *ngFor="let timeline of groupTimelines; trackBy: trackByFn" class="mb-4 timeline-entity main-content">
    <ng-container *ngTemplateOutlet="elementHeader; context: { entity: timeline }"></ng-container>

    <p *ngIf="timeline.body" class="timeline-entity__message">{{ timeline.body }}</p>
    <ng-container *ngIf="timeline.type === timelineType.Memory && isMember">
      <ct-journal-entry
        *ngIf="timeline.journal; else noEntity"
        [journalEntry]="toJournalEntry(timeline.journal)"
        [canEdit]="false"
      ></ct-journal-entry>
    </ng-container>
    <ng-container *ngIf="timeline.type === timelineType.Story && isMember">
      <ct-blog-post-card
        *ngIf="timeline.story; else noEntity"
        [post]="timeline.story"
        [showLink]="true"
        [showStatus]="true"
        imgHeight="220px"
        imgWidth="100%"
        [link]="getStoryLink(timeline)"
      ></ct-blog-post-card>
    </ng-container>
    <ng-container *ngIf="timeline.type === timelineType.Trip && isMember">
      <ct-trip-card
        *ngIf="timeline.trip; else noEntity"
        class="cursor--pointer"
        [trip]="timeline.trip"
        [link]="getTripLink(timeline)"
      ></ct-trip-card>
    </ng-container>
    <ng-container *ngIf="timeline.type === timelineType.Video && isMember">
      <ct-video-item-preview *ngIf="timeline.video; else noEntity" [video]="timeline.video" height="150px">
      </ct-video-item-preview>
    </ng-container>
    <ng-container *ngIf="timeline.type === timelineType.Document && isMember">
      <div *ngIf="timeline.document; else noEntity" class="document">
        <ct-link [link]="timeline.document?.path">
          <ct-icon icon="file-save"></ct-icon>
        </ct-link>
        <div class="event timeline-entity col-12">
          <h2 class="group-timeline__event-title">{{ timeline.document.title }}</h2>
          <h2 class="group-timeline__event-title">{{ timeline.document.originalname }}</h2>
          <p *ngIf="timeline.document.description" [innerHTML]="timeline.document.description | safe : 'html'"></p>
          <p class="group-timeline__date">
            {{ timeline.document.createdOn | date : 'fullDate' }}
            {{ timeline.document.createdOn | date : 'shortTime' }}
          </p>
        </div>
      </div>
    </ng-container>
    <ng-container *ngTemplateOutlet="elementFooter; context: { entity: timeline }"></ng-container>
  </div>
</div>
<div
  *ngIf="showLoadButton && groupTimelines?.length"
  class="load-more"
  [ngClass]="{ disabled: loading }"
  (click)="onScroll()"
  disabled="loading"
>
  Load More
</div>

<ng-template
  #elementHeader
  let-entity="entity"
  let-author="entity?.author"
  let-type="entity?.type"
  let-createdOn="entity?.createdOn"
>
  <div class="header row">
    <div class="user col-12">
      <ct-user-avatar
        class="user__avatar"
        [src]="getCroppedThumbPublicUrl(author?.photo?.publicUrl)"
        width="32px"
        height="32px"
      ></ct-user-avatar>
      <p class="user__link">
        <span
          *ngIf="author; else deletedUser"
          class="user__name"
          [routerLink]="['/user-profile']"
          [queryParams]="{ userId: author.userId }"
          >{{ author.firstName }} {{ author.lastName }}</span
        >
        <ng-template #deletedUser>
          <span class="user__name">Deleted User</span>
        </ng-template>
        <ng-container *ngIf="type === timelineType.Trip">
          <span class="user__subtitle"> shared the trip on {{ createdOn | date : 'MMM d yyyy, HH:mm' }} </span>
        </ng-container>
        <ng-container *ngIf="type === timelineType.Memory">
          <span class="user__subtitle"> shared the memory on {{ createdOn | date : 'MMM d yyyy, HH:mm' }} </span>
        </ng-container>
        <ng-container *ngIf="type === timelineType.Story">
          <span class="user__subtitle"> shared the story on {{ createdOn | date : 'MMM d yyyy, HH:mm' }} </span>
        </ng-container>
      </p>
      <ct-icon
        *ngIf="isAuthor(entity) || isAdmin"
        (click)="deleteEntity(entity)"
        icon="delete"
        class="delete-entity__icon"
      ></ct-icon>
    </div>
  </div>
</ng-template>

<ng-template #elementFooter let-entity="entity">
  <div class="row">
    <div class="col-12 extra-features">
      <ct-like
        class="extra-features__action"
        [likes]="entity.metadata?.likes ?? []"
        [user]="loggedInUser$ | async"
        (like)="onLike(entity)"
        (dislike)="onDislike(entity)"
      ></ct-like>
      <div class="extra-features__action" (click)="toggleComments(entity)">
        <ct-icon icon="chat"></ct-icon>
        {{ entity.metadata?.threads?.length }}
      </div>
    </div>
  </div>
  <ct-entity-comments
    *ngIf="entity.id === activeCommentsEntityId"
    class="mt-4"
    [entityId]="entity?.metadataId ?? entity.entityId"
    [entityType]="getEntityType(entity)"
    [disableComments]="false"
    [fetchOnInit]="false"
    [threads]="entity.metadata.threads"
    [user]="loggedInUser$ | async"
  ></ct-entity-comments>
</ng-template>

<ng-template #noEntity>
  <div class="no-entity">
    {{ 'Shared entity is not available anymore' | translate }}
  </div>
</ng-template>
