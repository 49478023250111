import { HttpEvent, HttpEventType } from '@angular/common/http';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SpinnerService } from '@ct/components/spinner';
import { UploadedImage } from '@ct/core';
import { getCroppedThumbPublicUrl } from '@ct/shared/helpers';
import { finalize, map } from 'rxjs/operators';

import { DialogButton } from '../../../../../dialog';
import { FILE_UPLOAD_DEFAULT_CONFIG } from '../../../file-upload';
import { ImageUpload } from '../../classes';
import { ImageUploadDialogTab } from '../../enums';
import { ImageUploadConfig } from '../../interfaces';

const UPLOAD_QUEUE_LIMIT = 3;

@Component({
  selector: 'ct-image-upload-dialog',
  templateUrl: './image-upload-dialog.component.html',
  styleUrls: ['./image-upload-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImageUploadDialogComponent implements OnInit {
  public readonly getCroppedThumbPublicUrl = getCroppedThumbPublicUrl;
  public config: ImageUploadConfig = FILE_UPLOAD_DEFAULT_CONFIG;
  public tabIndex = ImageUploadDialogTab.Upload;
  public loading = false;
  public uploadedImages: ImageUpload[] = [];

  public closeButton: DialogButton = {
    labelKey: 'COMPONENTS.FILE_UPLOAD.CLOSE',
    clicked: () => this.dialogRef.close(this.newlyUploadedImages)
  };

  public selectButton: DialogButton = {
    labelKey: 'COMPONENTS.FILE_UPLOAD.SELECT',
    clicked: () => this.dialogRef.close(this.selectedImages)
  };

  protected finishedUpload: boolean;

  get newlyUploadedImages() {
    return this.uploadedImages.filter((i) => i.isNew).map((i) => i.data);
  }

  get errorImages() {
    return this.uploadedImages.filter((i) => i.isNew && i.error);
  }

  get selectedImages() {
    return this.uploadedImages.filter((i) => i.selected).map((i) => i.data);
  }

  get isOneImageSelected() {
    return this.uploadedImages.some((image) => image.selected);
  }

  get buttons() {
    return [this.config.selectable ? this.selectButton : this.closeButton];
  }

  get closeIconParams() {
    return !this.config.selectable ? this.newlyUploadedImages : [];
  }

  private uploadImagesQueue: ImageUpload[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { config: ImageUploadConfig },
    private dialogRef: MatDialogRef<ImageUploadDialogComponent>,
    private spinnerService: SpinnerService,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    this.config = { ...this.config, ...this.data?.config };
  }

  ngOnInit() {
    if (this.config.getAllImagesFn) {
      this.loading = true;
      this.config.getAllImagesFn().subscribe((images) => {
        this.uploadedImages = [
          ...this.uploadedImages,
          ...images.map((image: UploadedImage) => new ImageUpload({} as File, false, true, false, 100, false, image))
        ];
        this.loading = false;
        this.changeDetectorRef.detectChanges();
      });
    }
  }

  onSelectFiles(imageFiles: File[]) {
    this.tabIndex = ImageUploadDialogTab.MyImages;
    this.changeDetectorRef.detectChanges();
    this.uploadedImages = [...imageFiles.map((file: File) => new ImageUpload(file, true)), ...this.uploadedImages];
    this.uploadedImages.forEach((image: ImageUpload) => this.addToQueue(image));
    this.uploadFromQueue();
  }

  addToQueue(image: ImageUpload) {
    if ((image.done && image.data) || !this.config.uploadFn) {
      return;
    }
    this.uploadImagesQueue.push(image);
  }

  uploadFromQueue() {
    const queueLimit = this.config?.queueLimit || UPLOAD_QUEUE_LIMIT;
    this.uploadImagesQueue.splice(0, queueLimit).forEach((image) => this.uploadImage(image));
    this.uploadImagesQueue.forEach((image) => image.setProgress(1, 100));
    this.changeDetectorRef.detectChanges();
  }

  uploadFirstFromQueue() {
    if (this.uploadImagesQueue.length) {
      this.uploadImage(this.uploadImagesQueue.shift() as ImageUpload);
    } else if (!this.config.showExisting) {
      this.finishedUpload = true;
      this.spinnerService.hide();
      this.changeDetectorRef.markForCheck();
    }
  }

  uploadImage(image: ImageUpload) {
    if ((image.done && image.data) || !this.config.uploadFn) {
      this.uploadFirstFromQueue();
      return;
    }

    if (!this.config.showExisting) {
      this.spinnerService.show({ instant: true });
    }

    return this.config
      .uploadFn(image?.file)
      .pipe(
        map((event: HttpEvent<any>) => {
          if (event?.type !== HttpEventType.UploadProgress) {
            return event;
          }
          image.setProgress(event.loaded, event.total as number);
          this.changeDetectorRef.detectChanges();
        }),
        finalize(() => {
          this.uploadFirstFromQueue();
        })
      )
      .subscribe(
        (event: any) => {
          if (this.config.noProgressTracking) {
            image.setSuccess(event);
            this.changeDetectorRef.detectChanges();
            return;
          }
          if (event?.type !== HttpEventType.Response) {
            return;
          }
          image.setSuccess(event?.body?.data);
          this.changeDetectorRef.detectChanges();
        },
        () => {
          image.setError();
          this.spinnerService.hide();
          this.changeDetectorRef.detectChanges();
        }
      );
  }

  onSelectImage(image: ImageUpload) {
    if (!this.config?.selectable) {
      return;
    }
    if (!this.config?.multiple && this.isOneImageSelected) {
      this.deselectAll();
    }
    image.selected = !image.selected;
    this.changeDetectorRef.detectChanges();
  }

  deselectAll() {
    this.uploadedImages.forEach((image) => (image.selected = false));
  }
}
