import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DialogService, MediaSelectDialogComponent, SpinnerService } from '@ct/components';
import {
  ChannelVideoUploadApiService,
  DestroyableFeature,
  Features,
  FormStateDispatcher,
  UploadedImage,
  VideoOrientation,
  VideoUploadEntity
} from '@ct/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, filter, finalize, map, switchMap } from 'rxjs/operators';

@Component({
  selector: 'ct-select-video-dialog',
  templateUrl: './select-video-dialog.component.html',
  styleUrls: ['./select-video-dialog.component.scss'],
  providers: [FormStateDispatcher],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@Features([DestroyableFeature()])
export class SelectVideoDialogComponent {
  static dialogConfig: MatDialogConfig = {
    minWidth: '700px'
  };
  public readonly destroyed$: Observable<void>;
  protected readonly uploadingMedia$ = new BehaviorSubject<boolean>(false);

  constructor(
    protected dialogService: DialogService,
    private dialogRef: MatDialogRef<SelectVideoDialogComponent>,
    private spinnerService: SpinnerService,
    private readonly snackBar: MatSnackBar,
    private videoUploadApiService: ChannelVideoUploadApiService
  ) {}

  onSelect(video: VideoUploadEntity) {
    this.dialogRef.close(video);
  }

  onSelectExisting(): void {
    this.dialogService
      .open(MediaSelectDialogComponent, {
        data: {
          config: {
            titleKey: 'MY_ACCOUNT.SELECT_FILES',
            multiple: false,
            selectable: true,
            videoOnly: true,
            firstTabKey: 'MY_ACCOUNT.MY_VIDEOS',
            getAllVideosFn: () => this.videoUploadApiService.getAllVideos()
          }
        }
      })
      .afterClosed()
      .pipe(filter(Boolean))
      .subscribe((result) => {
        const { video } = result as unknown as { video: VideoUploadEntity; images: UploadedImage[] };
        if (video) {
          this.onSelect(video);
        }
      });
  }

  async onUploadVideo(files: File[]) {
    const file = files[0];
    let orientation = VideoOrientation.Landscape;
    let width = 0;
    let height = 0;
    const duration = await this.onGetDuration(file);
    try {
      const result = (await this.onGetOrientation(file)) ?? { width, height, orientation };
      orientation = result.orientation;
      width = result.width;
      height = result.height;
    } catch (e) {
      return this.snackBar.open(`Error parsing video "${file.name}". Please, select another video.`, 'close', {
        duration: 10000,
        horizontalPosition: 'right',
        verticalPosition: 'bottom'
      });
    }
    this.spinnerService.show({ instant: true });
    this.videoUploadApiService
      .getUploadLink({ orientation, duration, width, height, fileName: file.name })
      .pipe(
        switchMap(({ uploadLink, ...video }) => {
          return this.videoUploadApiService.useUploadLink(file, uploadLink).pipe(map(() => video));
        }),
        finalize(() => {
          this.spinnerService.hide();
          this.uploadingMedia$.next(false);
        }),
        catchError((err) => {
          if (err.error.error && err.error.message) {
            this.snackBar.open(`${err.error.error}: ${err.error.message}`, 'close', {
              duration: 10000,
              horizontalPosition: 'right',
              verticalPosition: 'bottom'
            });
          }
          return of(null);
        })
      )
      .subscribe((video: VideoUploadEntity | null) => {
        if (video) {
          this.onSelect(video);
        }
      });
  }

  async onGetOrientation(videoFile: Blob) {
    // eslint-disable-next-line no-prototype-builtins
    if (HTMLVideoElement.prototype.hasOwnProperty('requestVideoFrameCallback')) {
      const video = await this.getVideoElement(videoFile);
      video.muted = true;
      video.playbackRate = 16;
      const { videoWidth, videoHeight } = video;
      video.pause();
      window.URL.revokeObjectURL(video.src);
      video.remove();
      const orientation = videoWidth < videoHeight ? VideoOrientation.Portrait : VideoOrientation.Landscape;
      return { width: videoWidth, height: videoHeight, orientation };
    } else {
      console.error("your browser doesn't support this API yet");
    }
  }

  async onGetDuration(videoFile: Blob) {
    return new Promise<number>((resolve) => {
      // eslint-disable-next-line no-prototype-builtins
      const video = document.createElement('video');
      video.preload = 'metadata';

      video.onloadedmetadata = () => {
        window.URL.revokeObjectURL(video.src);
        const duration = video.duration;
        video.remove();
        resolve(duration);
      };

      video.src = URL.createObjectURL(videoFile);
    });
  }

  private async getVideoElement(videoFile: Blob) {
    const video = document.createElement('video');
    video.crossOrigin = 'anonymous';
    video.style.display = 'none';
    video.src = URL.createObjectURL(videoFile);
    document.body.append(video);
    await video.play();
    return video;
  }
}
