import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogButton } from '@ct/components/dialog';
import { SelectOption } from '@ct/components/select';
import { SpinnerService } from '@ct/components/spinner/services';
import { ChannelsQuery, FormStateDispatcher, GroupTimelineType } from '@ct/core';
import { Observable, Subject } from 'rxjs';
import { finalize, map, switchMap, takeUntil } from 'rxjs/operators';

import { GroupApiService } from '../group-shared/services';
import { JournalEntryApiService } from '../trip-shared/services';

@Component({
  selector: 'ct-repost-photos-channel-dialog',
  templateUrl: './repost-photos-channel-dialog.component.html',
  styleUrls: ['./repost-photos-channel-dialog.component.scss'],
  providers: [FormStateDispatcher],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RepostPhotosChannelDialogComponent implements OnDestroy {
  public readonly destroyed$ = new Subject<void>();

  public isLoading = false;

  public buttons: DialogButton[] = [
    {
      labelKey: 'COMMON.CANCEL',
      color: 'primary',
      clicked: () => this.onCancel()
    },
    {
      labelKey: 'Repost',
      disabled: true,
      clicked: () => {
        this.onSave();
      }
    }
  ];

  public channelsCtrl = new FormControl<string>('', [Validators.required]);
  public channelsList$: Observable<SelectOption[]> = this.channelsQuery.selectChannels$.pipe(
    map((channels) => channels.map((channel) => ({ label: channel.title, value: channel.id as string })))
  );

  constructor(
    @Inject(MAT_DIALOG_DATA) public photoIds: string[],
    private dialogRef: MatDialogRef<RepostPhotosChannelDialogComponent>,
    private spinnerService: SpinnerService,
    private groupApiService: GroupApiService,
    private journalEntryApiService: JournalEntryApiService,
    private changeDetectorRef: ChangeDetectorRef,
    private channelsQuery: ChannelsQuery
  ) {
    this.channelsCtrl.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe((channelId) => {
      this.buttons[1].disabled = this.channelsCtrl.invalid;
      this.buttons = [...this.buttons];
      this.changeDetectorRef.detectChanges();
    });
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  onSave() {
    this.isLoading = true;
    this.spinnerService.show();
    this.changeDetectorRef.markForCheck();

    const channelId = this.channelsCtrl.value as string;

    this.journalEntryApiService
      .create({ body: undefined, photoIds: this.photoIds, groupIds: [channelId] })
      .pipe(
        switchMap((memory) =>
          this.groupApiService.addToTimeline(channelId, {
            type: GroupTimelineType.Memory,
            body: undefined,
            entityId: memory.id
          })
        ),
        finalize(() => {
          this.isLoading = false;
          this.spinnerService.hide();
          this.changeDetectorRef.markForCheck();
        })
      )
      .subscribe(() => {
        this.dialogRef.close(true);
      });
  }

  onCancel() {
    this.dialogRef.close();
  }
}
