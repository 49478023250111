<ct-dialog-content titleKey="Select Video To Share" [closeIcon]="true" headerIcon="add-circle">
  <ct-file-upload-placeholder
    [multiple]="false"
    [disabled]="false"
    [accept]="documentMimeTypes"
    class="mb-2 mt-2"
    (selectFiles)="onSelectDocuments($event)"
  ></ct-file-upload-placeholder>
  <ct-spinner-simple class="uploading-media-spinner" *ngIf="uploadingMedia$ | async"></ct-spinner-simple>
</ct-dialog-content>
